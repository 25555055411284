<template>
    <gmap-map ref="myMapRef" :center="center" :zoom="13" style="width: 100%; height: 400px">
        <gmap-cluster :renderer="renderer">
            <gmap-marker :key="index" v-for="(m, index) in markers" :position="m.position" :clickable="true" :draggable="true" @click="openMarker(m.id)" @closeclick="openMarker(null)">
                <gmap-info-window :closeclick="true" @closeclick="openMarker(null)" :opened="openedMarkerID === m.id">
                    <div>{{ m.infoText }}</div>
                </gmap-info-window>
            </gmap-marker>
        </gmap-cluster>
    </gmap-map>
</template>

<script>
    import { ResultSet } from '@cubejs-client/core';
    import { components } from 'gmap-vue';
    import { getGoogleMapsAPI } from 'gmap-vue';

    export default {
        name: 'GoogleMap',
        props: {
            resultSet: {
                type: ResultSet,
                required: true,
            },
            markers: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                isrollup: false,
                isdrildown: false,
                // zoom: 12,
                openedMarkerID: null,
                center: { lat: 41.4111, lng: -8.5237 },

                renderer: {
                    render: (cluster, stats) => {
                        console.log(cluster, stats);

                        let arrayLat = [];
                        let array = [];

                        for (let marker of cluster.markers) {
                            arrayLat.push(marker.position.lat());
                        }

                        arrayLat.forEach((pos) => {
                            this.markers.forEach((i) => {
                                if (i.position.lat == pos) {
                                    const index = this.markers.findIndex((i) => i.position.lat === pos);
                                    array.push(this.markers[index].value);
                                }
                            });
                        });

                        let sums = array.reduce((x, y) => x + y);
                        let sums1 = sums.toString();

                        // create svg url with fill color
                        const svg = window.btoa(`
                            <svg fill="#ff0000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
                            <circle cx="120" cy="120" opacity=".6" r="70" />
                            <circle cx="120" cy="120" opacity=".3" r="90" />
                            <circle cx="120" cy="120" opacity=".2" r="110" />
                            <circle cx="120" cy="120" opacity=".1" r="130" />
                            </svg>`);

                        return new this.google.maps.Marker({
                            position: cluster.position,
                            icon: {
                                url: `data:image/svg+xml;base64,${svg}`,
                                scaledSize: {
                                    width: 30,
                                    height: 30,
                                },
                            },
                            label: sums1,
                            // adjust zIndex to be above other markers
                            zIndex: 1000,
                        });
                    },
                },
            };
        },
        components: {
            'gmap-cluster': components.Cluster,
        },
        computed: {
            google() {
                return getGoogleMapsAPI();
            },
        },
        created() {},
        mounted() {
            this.$refs.myMapRef.$mapPromise.then((map) => {
                map.addListener('click', (mapsMouseEvent) => {
                    console.log(mapsMouseEvent.latLng.lat());
                    console.log(mapsMouseEvent.latLng.lng());
                });
            });
        },
        methods: {
            openMarker(id) {
                this.openedMarkerID = id;
                // console.log(this.openedMarkerID);
                // console.log(this.markers[this.openedMarkerID - 1].value);
            },
            showPosition(event, two) {
                console.log(event);
                console.log(event.latLng);
            },
        },
    };
</script>
