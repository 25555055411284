<template>
    <div>
        <h1 class="card-title">
            {{ title }}
        </h1>
        <span class="card-content">
            {{ count }}
        </span>
    </div>
</template>

<script>
    export default {
        name: 'Card',
        props: {
            title: String,
            resultSet: Object,
        },
        data() {
            return {
                count: this.resultSet
                    .rawData() //resultset method - ler os dados
                    .map((item) => Object.values(item).map((value) => value))
                    .join(' '),
            };
        },
    };
</script>
