<template>
    <div class="chart-renderer" v-if="resultSet">
        <v-chart v-if="showChart && option" class="chart" :option="option" ref="chart" @click="change($event)" />
        <GoogleMaps v-if="chartType === 'map'" :resultSet="resultSet" :markerss="markerss"> </GoogleMaps>

        <v-row>
            <v-col>
                <!-- <v-fab-transition> -->
                <v-btn v-show="!table" class="ma-2" @click="showTable()" outlined color="primary"> Values Table </v-btn>
                <v-btn v-show="table" class="ma-2" @click="BackTable()" outlined color="primary"> Back </v-btn>
                <v-btn v-show="!percentTable" class="ma-2" @click="showTablePercent()" outlined color="primary"> Percentage Table </v-btn>
                <v-btn v-show="percentTable" class="ma-2" @click="BackTablePercent()" outlined color="primary"> Back </v-btn>
                <!-- </v-fab-transition> -->
            </v-col>
        </v-row>

        <TablePercent v-if="percentTable === true" :result-set="resultSet" :uniqX="uniqX" :series="series" :indicator="indicator" :chartType="chartType" :markerss="markerss"></TablePercent>
        <Table v-if="table === true" :result-set="resultSet" :uniqX="uniqX" :series="series" :indicator="indicator" :chartType="chartType" :markerss="markerss"></Table>
    </div>
</template>

<script>
    import { ResultSet } from '@cubejs-client/core';
    import { use } from 'echarts/core';
    import { CanvasRenderer } from 'echarts/renderers';
    import Table from './Table.vue';
    import TablePercent from './TablePercent.vue';
    import moment from 'moment';
    import { BarChart, LineChart, PieChart, ScatterChart, RadarChart } from 'echarts/charts';
    import { GridComponent, TooltipComponent, LegendComponent, TitleComponent, ToolboxComponent, DataZoomComponent } from 'echarts/components';
    import VChart from 'vue-echarts';
    import _ from 'lodash';
    import GoogleMaps from '../KPIs/GoogleMaps.vue';

    use([CanvasRenderer, ScatterChart, LineChart, BarChart, PieChart, RadarChart, GridComponent, TitleComponent, TooltipComponent, LegendComponent, ToolboxComponent, DataZoomComponent]);

    export default {
        name: 'ChartRenderer',
        props: {
            chartType: {
                type: String,
                required: true,
            },
            resultSet: {
                type: ResultSet,
                required: true,
            },
        },
        data() {
            return {
                table: false,
                percentTable: false,
                showChart: true,
                uniqX: [],
                series: [],
                indicator: [],
                markerss: [],
                option: null,
            };
        },
        components: {
            VChart,
            Table,
            TablePercent,
            GoogleMaps,
        },
        watch: {
            chartType() {
                this.option = null;
                this.showChart = false;
                this.echartOption();
                this.$nextTick(() => {
                    this.showChart = true;
                });
            },
        },
        created() {
            this.echartOption();
        },
        mounted() {
            const seriesNames = this.resultSet.seriesNames();

            let pivot = this.resultSet.chartPivot();

            const pre_data = [];
            seriesNames.forEach((e) => {
                const data = pivot.map((p) => [p.x, p[e.key]]);
                pre_data.push({ name: e.yValues[0], data });
            });

            let indicator = pre_data.map((s) => {
                return { name: s.name };
            });

            let YAxis = pre_data.map((s) => {
                return s.data;
            }); //y data array

            let y = [];
            YAxis.forEach((item) => {
                y.push(...item); //push array's outside the main array
            });

            // a - acc; b - cur --loop through array(y)
            let yAxis = y.reduce((a, b) => {
                a[b[0]] ? a[b[0]].push(b[1]) : (a[b[0]] = [b[1]]);
                return a;
            }, {});

            let i = 1;
            // read the json
            Object.keys(yAxis).forEach((key) => {
                let [a, b] = key.split(',');
                a = parseFloat(a);
                b = parseFloat(b);

                const lat = a > b ? a : b;
                const lng = a > b ? b : a;

                this.markerss.push({
                    id: i,
                    position: { lat, lng },
                    infoText: indicator[0].name.split('.')[1] + ': ' + yAxis[key][0],
                    indicator: indicator[0].name.split('.')[1],
                    value: yAxis[key][0],
                });
                i++;
            });
        },
        methods: {
            change($event) {
                this.$emit('change', $event);
            },

            echartOption() {
                if (this.chartType === 'bar') this.option = this.optionBar(this.resultSet);
                else if (this.chartType === 'line') this.option = this.optionLine(this.resultSet);
                else if (this.chartType === 'pie') this.option = this.optionPie(this.resultSet);
                else if (this.chartType === 'area') this.option = this.optionArea(this.resultSet);
                else if (this.chartType === 'doughnut') this.option = this.optionDoughnut(this.resultSet);
                else if (this.chartType === 'scatter') this.option = this.optionScatter(this.resultSet);
                else if (this.chartType === 'radar') this.option = this.optionRadar(this.resultSet);
            },

            showTable() {
                this.table = true;
            },

            BackTable() {
                this.table = false;
            },
            showTablePercent() {
                this.percentTable = true;
            },

            BackTablePercent() {
                this.percentTable = false;
            },

            changeXAxis(data, pivot) {
                if (_.has(data, 'DimCalendar.date.year')) {
                    for (let i = 0; i < pivot.length; i++) {
                        if (moment(pivot[i].x, 'YYYY').format('YYYY') != pivot[i].x) {
                            let change = moment(pivot[i].x).format('YYYY');
                            pivot[i].x = change;
                        }
                    }
                } else if (_.has(data, 'DimCalendar.date.month')) {
                    for (let i = 0; i < pivot.length; i++) {
                        if (moment(pivot[i].x, 'YYYY-MM').format('YYYY-MM') != pivot[i].x) {
                            let change = moment(pivot[i].x).format('YYYY-MM');
                            pivot[i].x = change;
                        }
                    }
                } else if (_.has(data, 'DimCalendar.date.week')) {
                    for (let i = 0; i < pivot.length; i++) {
                        if (moment(pivot[i].x, 'YYYY-MM-DD').format('YYYY-MM-DD') != pivot[i].x) {
                            let change = moment(pivot[i].x).format('YYYY-MM-DD');
                            pivot[i].x = change;
                        }
                    }
                } else if (_.has(data, 'DimCalendar.date.day')) {
                    for (let i = 0; i < pivot.length; i++) {
                        if (moment(pivot[i].x, 'YYYY-MM-DD').format('YYYY-MM-DD') != pivot[i].x) {
                            let change = moment(pivot[i].x).format('YYYY-MM-DD');
                            pivot[i].x = change;
                        }
                    }
                } else if (_.has(data, 'DimCalendar.date.quarter')) {
                    const isValidDate = function (date) {
                        return new Date(date) !== 'Invalid Date' && !isNaN(new Date(date));
                    };
                    for (let i = 0; i < pivot.length; i++) {
                        if (isValidDate(pivot[i].x)) {
                            let ano = moment(pivot[i].x).format('YYYY');
                            let change = moment(pivot[i].x).quarter() + ' ' + ano;
                            pivot[i].x = change;
                        }
                    }
                } else if (_.has(data, 'DimTime.fulltime.hour')) {
                    for (let i = 0; i < pivot.length; i++) {
                        if (moment(pivot[i].x, 'HH').format('HH') != pivot[i].x) {
                            let change = moment(pivot[i].x).format('HH');
                            pivot[i].x = change;
                        }
                    }
                }
                return pivot;
            },

            optionLine(resultSet) {
                const seriesNames = resultSet.seriesNames();

                let pivot = resultSet.chartPivot();

                let data = resultSet.backwardCompatibleData[0];
                console.log(resultSet);

                for (let i = 0; i < data.length; i++) {
                    pivot = this.changeXAxis(data[i], pivot);
                }
                console.log(pivot);
                const pre_data = [];
                seriesNames.forEach((e) => {
                    const data = pivot.map((p) => [p.x, p[e.key]]);
                    pre_data.push({ name: e.yValues[0], data });
                });

                let Xaxis = pre_data.map((s) => {
                    return s.data;
                });

                let x = [];
                Xaxis.forEach((item) => {
                    x.push(...item);
                });

                let xx = [];
                x.forEach((e) => {
                    xx.push(e[0]);
                });

                this.uniqX = [...new Set(xx)];

                let xName = '';
                if (_.has(resultSet.loadResponses[0].query.timeDimensions[0], 'granularity')) {
                    xName = resultSet.loadResponses[0].query.timeDimensions[0].granularity;
                } else {
                    xName = 'Dimensions';
                }

                this.series = resultSet.series().map((seriesItem) => ({
                    name: seriesItem.title.split(',')[0],
                    data: seriesItem.series.map((item) => item.value),
                    type: 'line',
                    emphasis: {
                        focus: 'series',
                    },
                    // label: {
                    //     show: true,
                    //     position: 'inside',
                    // },
                }));

                let option = {
                    tooltip: {
                        trigger: 'axis',
                    },
                    xAxis: {
                        type: 'category',
                        data: this.uniqX,
                        name: xName.charAt(0).toUpperCase() + xName.slice(1),
                        // nameLocation: 'middle',
                        nameTextStyle: {
                            fontSize: 15,
                        },
                    },
                    legend: {},
                    yAxis: {
                        type: 'value',
                    },
                    series: this.series,
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true,
                    },
                    toolbox: {
                        top: '5%',
                        feature: {
                            saveAsImage: {},
                            // dataView: { readOnly: false },
                            magicType: { show: true, type: ['bar'] },
                            restore: { show: true },
                            dataZoom: {
                                yAxisIndex: 'none',
                            },
                        },
                    },
                };
                return option;
            },
            optionPie(resultSet) {
                let XYGroup = resultSet.series()[0].series.map((item) => [item.x, item.value]);
                console.log(XYGroup);
                this.uniqX = resultSet.loadResponses[0].query.measures;

                let yAxis = XYGroup.map((s1) => {
                    return { value: s1[1], name: s1[0] };
                });

                let xAxis = XYGroup.map((s1) => {
                    return s1[0];
                });
                console.log(xAxis);

                this.series = [
                    {
                        type: 'pie',
                        radius: '50%',
                        data: yAxis,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)',
                            },
                        },
                        label: {
                            show: true,
                            position: 'inner',
                            formatter: function (params) {
                                return params.value + '\n';
                            },
                        },
                    },
                ];

                let option = {
                    tooltip: {
                        trigger: 'item',
                        formatter: '{b} : {c}',
                    },
                    legend: {
                        orient: 'vertical',
                        left: '5%',
                        data: xAxis,
                    },
                    toolbox: {
                        top: '5%',
                        feature: {
                            saveAsImage: {},
                        },
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: '20',
                            fontWeight: 'bold',
                        },
                    },
                    series: this.series,
                };

                return option;
            },
            optionBar(resultSet) {
                const seriesNames = _.assign([], resultSet.seriesNames());

                let pivot = _.assign([], resultSet.chartPivot());

                let data = _.assign([], resultSet.backwardCompatibleData[0]);

                for (let i = 0; i < data.length; i++) {
                    pivot = this.changeXAxis(data[i], pivot);
                }

                const pre_data = [];
                seriesNames.forEach((e) => {
                    const data = pivot.map((p) => [p.x, p[e.key]]);
                    pre_data.push({ name: e.yValues[0], data });
                });

                let Xaxis = pre_data.map((s) => {
                    return s.data;
                });

                let x = [];
                Xaxis.forEach((item) => {
                    x.push(...item);
                });

                let xx = [];
                x.forEach((e) => {
                    return xx.push(e[0]);
                });

                this.uniqX = [...new Set(xx)];

                let xName = '';
                if (_.has(resultSet.loadResponses[0].query.timeDimensions[0], 'granularity')) {
                    xName = resultSet.loadResponses[0].query.timeDimensions[0].granularity;
                } else {
                    xName = 'Dimensions';
                }

                this.series = _.assign([], resultSet.series()).map((seriesItem) => ({
                    name: seriesItem.title.split(',')[0],
                    data: seriesItem.series.map((item) => item.value),
                    // data: seriesItem.series.map((item) => (item.value > 0 ? item.value : null)),
                    type: 'bar',
                    emphasis: {
                        focus: 'series',
                    },
                    label: {
                        show: true,
                        position: 'inside',
                    },
                }));

                let option = {
                    tooltip: {
                        trigger: 'item',
                        formatter: '{a} <br/>{b} : {c}',
                    },
                    xAxis: {
                        type: 'category',
                        data: this.uniqX,
                        name: xName.charAt(0).toUpperCase() + xName.slice(1),
                        // nameLocation: 'middle',
                        nameTextStyle: {
                            fontSize: 15,
                        },
                    },
                    yAxis: {
                        type: 'value',
                    },
                    series: this.series,
                    legend: {},
                    dataZoom: [
                        {
                            startValue: '2020-07-21',
                            // type: 'inside',
                            // xAxisIndex: [0],
                            // start: 1,
                            // end: 35,
                        },
                    ],
                    toolbox: {
                        top: '5%',
                        feature: {
                            saveAsImage: {},
                            // dataView: { readOnly: false },
                            magicType: { show: true, type: ['line'] },
                            restore: { show: true },
                            dataZoom: {
                                yAxisIndex: 'none',
                            },
                        },
                    },
                };
                // this.$emit('getSeries', series);
                return option;
            },
            optionArea(resultSet) {
                this.option = [];
                const seriesNames = resultSet.seriesNames();

                let pivot = resultSet.chartPivot();

                let data = resultSet.backwardCompatibleData[0];

                for (let i = 0; i < data.length; i++) {
                    pivot = this.changeXAxis(data[i], pivot);
                }

                const pre_data = [];
                seriesNames.forEach((e) => {
                    const data = pivot.map((p) => [p.x, p[e.key]]);
                    pre_data.push({ name: e.yValues[0], data });
                });

                let Xaxis = pre_data.map((s) => {
                    return s.data;
                });

                let x = [];
                Xaxis.forEach((item) => {
                    x.push(...item);
                });

                let xx = [];
                x.forEach((e) => {
                    return xx.push(e[0]);
                });

                this.uniqX = [...new Set(xx)];

                let xName = '';
                if (_.has(resultSet.loadResponses[0].query.timeDimensions[0], 'granularity')) {
                    xName = resultSet.loadResponses[0].query.timeDimensions[0].granularity;
                } else {
                    xName = 'Dimensions';
                }

                this.series = resultSet.series().map((seriesItem) => ({
                    name: seriesItem.title.split(',')[0],
                    data: seriesItem.series.map((item) => item.value),
                    type: 'line',
                    stack: 'Ad',
                    smooth: true,
                    lineStyle: {
                        width: 0,
                    },
                    showSymbol: false,
                    areaStyle: {
                        opacity: 1,
                    },
                    emphasis: {
                        focus: 'series',
                    },
                }));

                let option = {
                    tooltip: {
                        trigger: 'item',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#6a7985',
                            },
                        },
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true,
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: this.uniqX,
                        name: xName.charAt(0).toUpperCase() + xName.slice(1),
                        // nameLocation: 'middle',
                        // nameGap: 25,
                        nameTextStyle: {
                            fontSize: 15,
                            // color: 'rgba(0, 0, 0, 0.93)',
                        },
                    },
                    yAxis: {
                        type: 'value',
                    },
                    toolbox: {
                        top: '5%',
                        feature: {
                            saveAsImage: {},
                            // dataView: { show: true, readOnly: false },
                            magicType: { show: true, type: ['bar'] },
                            restore: { show: true },
                            dataZoom: {
                                yAxisIndex: 'none',
                            },
                        },
                    },
                    series: this.series,
                    legend: {},
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: '20',
                            fontWeight: 'bold',
                        },
                    },
                };
                // this.$emit('getSeries', this.uniqX);
                return option;
            },
            optionDoughnut(resultSet) {
                let XYGroup = resultSet.series()[0].series.map((item) => [item.x, item.value]);

                // let XAxis = XYGroup.map((s) => {
                //   return s[0];
                // });

                this.uniqX = resultSet.loadResponses[0].query.measures;
                console.log(this.uniqX);

                let YAxis = XYGroup.map((s1) => {
                    return { value: s1[1], name: s1[0] };
                    //return {value:s1[1], name: moment(s1[0]).format('MMMM/YY')};
                });

                this.series = [
                    {
                        type: 'pie',
                        data: YAxis,
                        radius: ['40%', '70%'],
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 10,
                            borderColor: '#fff',
                            borderWidth: 2,
                        },

                        label: {
                            show: true,
                            position: 'inner',
                            formatter: function (params) {
                                return params.value + '\n';
                            },
                        },

                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '20',
                                fontWeight: 'bold',
                            },
                        },
                        labelLine: {
                            show: false,
                        },
                    },
                ];

                let option = {
                    tooltip: {
                        trigger: 'item',
                        //formatter: "{a} <br/> {b} : {c}",
                    },
                    legend: {
                        orient: 'vertical',
                        left: '5%',
                    },
                    toolbox: {
                        top: '5%',
                        feature: {
                            saveAsImage: {},
                        },
                    },
                    series: this.series,
                };
                return option;
            },
            optionScatter(resultSet) {
                const seriesNames = resultSet.seriesNames();

                let pivot = resultSet.chartPivot();

                let data = resultSet.backwardCompatibleData[0];

                for (let i = 0; i < data.length; i++) {
                    pivot = this.changeXAxis(data[i], pivot);
                }

                const pre_data = [];
                seriesNames.forEach((e) => {
                    const data = pivot.map((p) => [p.x, p[e.key]]);
                    pre_data.push({ name: e.yValues[0], data });
                });

                let Xaxis = pre_data.map((s) => {
                    return s.data;
                });

                let x = [];
                Xaxis.forEach((item) => {
                    x.push(...item);
                });

                let xx = [];
                x.forEach((e) => {
                    return xx.push(e[0]);
                });

                let uniqX = [...new Set(xx)];

                let xName = '';
                if (_.has(resultSet.loadResponses[0].query.timeDimensions[0], 'granularity')) {
                    xName = resultSet.loadResponses[0].query.timeDimensions[0].granularity;
                } else {
                    xName = 'Dimensions';
                }

                this.series = resultSet.series().map((seriesItem) => ({
                    name: seriesItem.title.split(',')[0],
                    data: seriesItem.series.map((item) => item.value),
                    symbolSize: 20,
                    type: 'scatter',
                    emphasis: {
                        focus: 'series',
                    },
                    // label: {
                    //     show: true,
                    //     position: 'inside',
                    // },
                }));

                let option = {
                    xAxis: {
                        scale: true,
                        data: uniqX,
                        name: xName.charAt(0).toUpperCase() + xName.slice(1),
                        // nameLocation: 'middle',
                        // nameGap: 25,
                        nameTextStyle: {
                            fontSize: 15,
                            // color: 'rgba(0, 0, 0, 0.93)',
                        },
                    },
                    yAxis: {
                        scale: true,
                    },
                    series: this.series,
                    tooltip: {
                        trigger: 'axis',
                    },
                    legend: {},
                    toolbox: {
                        top: '5%',
                        feature: {
                            saveAsImage: {},
                        },
                    },
                };
                // this.$emit('getSeries', series);
                return option;
            },
            optionRadar(resultSet) {
                const seriesNames = resultSet.seriesNames();

                let pivot = resultSet.chartPivot();

                let data = resultSet.backwardCompatibleData[0];

                for (let i = 0; i < data.length; i++) {
                    pivot = this.changeXAxis(data[i], pivot);
                }

                const pre_data = [];
                console.log(seriesNames);
                console.log(pivot);
                seriesNames.forEach((e) => {
                    const data = pivot.map((p) => [p.x, p[e.key]]);
                    pre_data.push({ name: e.yValues[0], data });
                });
                console.log(pre_data);
                this.indicator = pre_data.map((s) => {
                    return { name: s.name };
                });

                let YAxis = pre_data.map((s) => {
                    return s.data;
                }); //y data array

                let y = [];
                YAxis.forEach((item) => {
                    y.push(...item); //push array's outside the main array
                });

                // a - acc; b - cur --loop through array(y)
                let yAxis = y.reduce((a, b) => {
                    a[b[0]] ? a[b[0]].push(b[1]) : (a[b[0]] = [b[1]]);
                    return a;
                }, {});

                this.series = [];
                // read the json
                Object.keys(yAxis).forEach((key) => {
                    this.series.push({
                        type: 'radar',
                        data: [{ name: key, value: yAxis[key] }],
                        symbolSize: 20,
                        tooltip: {
                            trigger: 'item',
                        },
                        emphasis: {
                            focus: 'series',
                        },
                    });
                });

                let option = {
                    radar: {
                        indicator: this.indicator,
                    },
                    series: this.series,
                    legend: {
                        orient: 'vertical',
                        left: 'left',
                    },
                    toolbox: {
                        top: '5%',
                        feature: {
                            saveAsImage: {},
                        },
                    },
                    tooltip: {
                        trigger: 'item',
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: '20',
                            fontWeight: 'bold',
                        },
                    },
                };
                // this.$emit('getSeries', series);
                return option;
            },
        },
    };
</script>

<style>
    .number-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
</style>
