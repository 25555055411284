//! REGISTER ROUTES IN MAIN ROUTER
//? route names are prefixed with the plugin id to avoid collisions with other plugins and to avoid collisions with the main routes
//? considering a plugin with the id 'google-calendar'
//? e.g. Route with name "Home" becomes "GoogleCalendar-Home"
//? e.g. Route with path "/home" becomes "/google-calendar/home"

import cubejs from '@cubejs-client/core';
/* Components */
import Explore from '../views/ExplorePage';
import Dashboard from '../views/DashboardPage';
import KPIs from '../views/KPIs';

import { routeName } from '../constants';

const cubejsApi = cubejs(process.env.VUE_APP_CUBEJS_TOKEN, {
    apiUrl: `${process.env.VUE_APP_CUBE_API_URL}`,
});

export default [
    {
        path: '/',
        name: 'Home',
        component: () => import('../views/App.vue'),
        children: [
            { path: '', alias: 'explore', name: 'Explore', component: Explore, props: { cubejsApi } },
            { path: 'dashboard', name: 'Dashboard', component: Dashboard, props: { cubejsApi } },
            { path: 'KPIs', name: 'KPIs', component: KPIs, props: { cubejsApi } },
        ],
    },
];
