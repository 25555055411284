import _ from 'lodash';
import { dateToGranularity, defaultOptions, defaultInitOptions, useCustomColors } from './utils';
import { ResultSet } from '@cubejs-client/core';

const useBarChart = (resultSet, _options = {}) => {
    const result = resultSet instanceof ResultSet ? resultSet : ResultSet.deserialize(resultSet);

    const granularity = _.get(result.query(), 'timeDimensions[0].granularity', null);
    const measures = _.get(result.query(), 'measures', []).join(' / ');
    const dimensions = _.get(result.query(), 'dimensions', []).join(' / ');
    const xAxis = result.chartPivot().map((p) => dateToGranularity(p.x, granularity));

    const customColors = useCustomColors(result.series().map((s) => s.key));

    const series = result.series().map((s) => {
        const serieName = result.seriesNames().find((e) => e.key === s.key);

        return {
            name: serieName.yValues[0],
            type: 'bar',
            stack: serieName.yValues[0],
            data: s.series.map((e) => ({
                value: e.value,
                itemStyle: {
                    color: customColors.getColorByKey(s.key),
                },
            })),
            emphasis: {
                focus: 'series',
            },
            // hide numbers
            // label: {
            //     show: true,
            //     position: 'inside',
            // },
        };
    });

    let options = {
        legend: {
            data: result.seriesNames().map((e) => ({
                name: e.yValues[0],
                itemStyle: {
                    color: customColors.getColorByKey(e.key),
                },
            })),
        },
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c}',
        },
        xAxis: {
            type: 'category',
            data: xAxis,
            name: granularity || dimensions,
        },
        yAxis: {
            type: 'value',
            name: measures,
            nameLocation: 'middle',
            nameGap: 60,
        },
        series,
        dataZoom: [
            {
                type: 'slider',
            },
            {
                type: 'inside',
            },
        ],
        toolbox: {
            feature: {
                magicType: { show: true, type: ['line'] },
            },
        },
    };

    const initOptions = {};

    return {
        result,
        customOptions: _.merge(options, defaultOptions, _options),
        initOptions: _.merge(initOptions, defaultInitOptions),
        series,
        xAxis,
        dimensions,
    };
};

export default useBarChart;
