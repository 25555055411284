import _ from 'lodash';
import { ResultSet } from '@cubejs-client/core';
import { dateToGranularity, useCustomColors, defaultOptions, defaultInitOptions } from './utils';

const useAreaChart = (resultSet, _options = {}) => {
    const result = resultSet instanceof ResultSet ? resultSet : ResultSet.deserialize(resultSet);

    const granularity = _.get(result.query(), 'timeDimensions[0].granularity', null);
    const measures = _.get(result.query(), 'measures', []).join(' / ');
    const dimensions = _.get(result.query(), 'dimensions', []).join(' / ');

    const xAxis = result.chartPivot().map((p) => dateToGranularity(p.x, granularity));

    const customColors = useCustomColors(result.series().map((s) => s.key));

    const series = result.series().map((s) => {
        const serieName = result.seriesNames().find((e) => e.key === s.key);

        return {
            name: serieName.yValues[0],
            type: 'line',
            stack: 'Ad',
            smooth: true,
            data: s.series.map((e) => ({
                value: e.value,
                itemStyle: {
                    color: customColors.getColorByKey(s.key),
                },
            })),
            emphasis: {
                focus: 'series',
            },
            showSymbol: false,
            lineStyle: {
                width: 0,
            },
            areaStyle: {
                opacity: 1,
            },
            // hide numbers
            // label: {
            //     show: true,
            //     position: 'inside',
            // },
        };
    });

    let options = {
        legend: {
            data: result.seriesNames().map((e) => ({
                name: e.yValues[0],
                itemStyle: {
                    color: customColors.getColorByKey(e.key),
                },
            })),
        },
        tooltip: {
            trigger: 'item',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985',
                },
            },
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: xAxis,
            nameTextStyle: {
                fontSize: 15,
            },
            name: granularity || dimensions,
        },
        yAxis: {
            type: 'value',
            name: measures,
            nameLocation: 'middle',
            nameGap: 60,
        },
        series,
        dataZoom: [
            {
                type: 'slider',
            },
            {
                type: 'inside',
            },
        ],
        emphasis: {
            label: {
                show: true,
                fontSize: '20',
                fontWeight: 'bold',
            },
        },
    };

    const initOptions = {};

    return {
        result,
        customOptions: _.merge(options, defaultOptions, _options),
        initOptions: _.merge(initOptions, defaultInitOptions),
        series,
        xAxis,
        dimensions,
    };
};

export default useAreaChart;
