<template>
    <div class="charts-wrapper">
        <v-chart class="chart" :option="option" />
    </div>
</template>

<script>
    import { use } from 'echarts/core';
    import { CanvasRenderer } from 'echarts/renderers';
    import { HeatmapChart } from 'echarts/charts';
    import moment from 'moment';
    import _ from 'lodash';
    import { GridComponent, TooltipComponent, LegendComponent, TitleComponent, MarkLineComponent, VisualMapComponent } from 'echarts/components';
    import VChart from 'vue-echarts';
    // import VChart, { THEME_KEY } from 'vue-echarts';
    // import { ref } from 'vue';
    use([CanvasRenderer, HeatmapChart, GridComponent, TitleComponent, TooltipComponent, LegendComponent, MarkLineComponent, VisualMapComponent]);
    export default {
        name: 'PieChart',
        components: {
            VChart,
        },
        props: {
            title: String,
            resultSet: Object,
        },
        // provide: {
        //     [THEME_KEY]: 'light',
        // },
        setup(props) {
            const seriesNames = props.resultSet.seriesNames();

            let pivot = props.resultSet.chartPivot();

            const pre_data = [];
            seriesNames.forEach((e) => {
                const data = pivot.map((p) => [p.x, p[e.key]]);
                pre_data.push({ name: e.yValues[0], data });
            });
            console.log(pre_data);
            let Xaxis = pre_data.map((s) => {
                return s.data;
            });

            let Yaxis = pre_data.map((s) => {
                return s.name;
            });
            console.log(Yaxis);

            let x = [];
            Xaxis.forEach((item) => {
                x.push(...item);
            });

            let xx = [];
            x.forEach((e) => {
                return xx.push(e[0]);
            });

            let uniqX = [...new Set(xx)];
            console.log(uniqX);

            let newuniqX = [];
            uniqX.forEach((item) => {
                newuniqX.push(moment(item).format('hh'));
            });
            console.log(newuniqX);

            let xName = '';
            if (_.has(props.resultSet.loadResponses[0].query.timeDimensions[0], 'granularity')) {
                xName = props.resultSet.loadResponses[0].query.timeDimensions[0].granularity;
            } else {
                xName = 'Dimensions';
            }
            let auxseries = props.resultSet.series().map((seriesItem) => ({
                data: seriesItem.series.map((item) => item.value),
            }));
            console.log(auxseries);

            let seriesData = [];
            let i = 0;
            let j = 0;
            for (let p in auxseries) {
                for (let l in auxseries[p].data) {
                    seriesData.push([i, j, auxseries[p].data[l]]);

                    j++;
                }
                i++;
                j = 0;
            }

            let data = seriesData.map(function (item) {
                return [item[1], item[0], item[2] || '-'];
            });

            let option = {
                tooltip: {
                    position: 'top',
                },
                grid: {
                    height: '50%',
                    top: '10%',
                },
                xAxis: {
                    type: 'category',
                    data: newuniqX,
                    splitArea: {
                        show: true,
                    },
                    name: xName.charAt(0).toUpperCase() + xName.slice(1),
                    nameTextStyle: {
                        fontSize: 15,
                    },
                },
                yAxis: {
                    type: 'category',
                    data: Yaxis,
                    splitArea: {
                        show: true,
                    },
                },
                visualMap: {
                    min: 0,
                    max: 10,
                    calculable: true,
                    orient: 'horizontal',
                    left: 'center',
                    bottom: '15%',
                },

                series: [
                    {
                        name: 'Número de tickets',
                        type: 'heatmap',
                        data: data,
                        label: {
                            show: true,
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowColor: 'rgba(0, 0, 0, 0.5)',
                            },
                        },
                    },
                ],

                // legend: {},
                toolbox: {
                    feature: {
                        saveAsImage: {},
                        restore: { show: true },
                        dataZoom: {
                            yAxisIndex: 'none',
                        },
                    },
                },
            };
            return { option };
        },
    };
</script>
<style scoped>
    .chart {
        height: 400px;
    }
</style>
