<template>
    <div class="charts-wrapper">
        <v-chart class="chart" :option="option" />
    </div>
</template>

<script>
    import { use } from 'echarts/core';
    import { CanvasRenderer } from 'echarts/renderers';
    import { BarChart } from 'echarts/charts';
    import moment from 'moment';
    import _ from 'lodash';
    import { GridComponent, TooltipComponent, LegendComponent, TitleComponent, MarkLineComponent } from 'echarts/components';
    import VChart from 'vue-echarts';
    // import VChart, { THEME_KEY } from 'vue-echarts';
    // import { ref } from 'vue';
    use([CanvasRenderer, BarChart, GridComponent, TitleComponent, TooltipComponent, LegendComponent, MarkLineComponent]);
    export default {
        name: 'PieChart',
        components: {
            VChart,
        },
        props: {
            title: String,
            resultSet: Object,
        },
        // provide: {
        //     [THEME_KEY]: 'light',
        // },
        setup(props) {
            const seriesNames = props.resultSet.seriesNames();

            let pivot = props.resultSet.chartPivot();

            const pre_data = [];
            seriesNames.forEach((e) => {
                const data = pivot.map((p) => [p.x, p[e.key]]);
                pre_data.push({ name: e.yValues[0], data });
            });

            let Xaxis = pre_data.map((s) => {
                return s.data;
            });

            let x = [];
            Xaxis.forEach((item) => {
                x.push(...item);
            });

            let xx = [];
            x.forEach((e) => {
                return xx.push(e[0]);
            });

            let uniqX = [...new Set(xx)];

            let newuniqX = [];
            uniqX.forEach((item) => {
                newuniqX.push(moment(item).format('YYYY'));
            });

            let xName = '';
            if (_.has(props.resultSet.loadResponses[0].query.timeDimensions[0], 'granularity')) {
                xName = props.resultSet.loadResponses[0].query.timeDimensions[0].granularity;
            } else {
                xName = 'Dimensions';
            }

            let series = props.resultSet.series().map((seriesItem) => ({
                name: seriesItem.title.split(',')[0],
                data: seriesItem.series.map((item) => item.value),
                type: 'bar',

                emphasis: {
                    focus: 'series',
                },
                label: {
                    show: true,
                    position: 'inside',
                },
                // markLine: {
                //     data: [
                //         {
                //             name: 'Parks with more than 1500 movements per month',
                //             yAxis: 1500,
                //         },
                //     ],
                //     lineStyle: {
                //         color: '#000000',
                //         type: 'solid',
                //     },
                // },
            }));
            let option = {
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b} : {c}',
                },
                xAxis: {
                    type: 'category',
                    data: newuniqX,
                    name: xName.charAt(0).toUpperCase() + xName.slice(1),
                    nameTextStyle: {
                        fontSize: 15,
                    },
                },
                yAxis: {
                    type: 'value',
                },

                series: series,

                legend: { padding: [40, 40, 40, 40] },
                toolbox: {
                    feature: {
                        saveAsImage: {},
                        dataView: { readOnly: false },
                        magicType: { show: true, type: ['line'] },
                        restore: { show: true },
                        dataZoom: {
                            yAxisIndex: 'none',
                        },
                    },
                },
            };
            return { option };
        },
    };
</script>
<style scoped>
    .chart {
        height: 400px;
    }
</style>
