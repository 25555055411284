import Vue from 'vue';
import './charts/index';
import * as GmapVue from 'gmap-vue';
// import GmapVue from 'gmap-vue';
import { components } from 'gmap-vue';
import VueApollo from 'vue-apollo';
import apolloClient from './graphql/client';
import VueSimpleAlert from 'vue-simple-alert';

Vue.use(VueApollo);
Vue.use(VueSimpleAlert);
Vue.use(GmapVue, {
    load: {
        key: 'AIzaSyCae_lSgk74PN2btkcwynMnB32m4wof_Gg',
        libraries: 'places',
    },
});

Vue.component('gmap-cluster', GmapVue.components.Cluster);

const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
});

import moment from 'moment';
moment.locale('en');
Vue.prototype.moment = moment;

export default {
    injections: {
        apolloProvider,
    },
};
