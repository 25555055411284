export default {
    qube: {
        API_URL: 'https://api.q-better.ioscience.iotech.pt',
        CUBE_API_URL: 'https://ds.iotech.pt/qbetter-qube/cubejs/cubejs-api/v1',
    },
    energy: {
        API_URL: 'https://api.q-better.ioscience.iotech.pt',
        CUBE_API_URL: 'https://ds.iotech.pt/qbetter-energy/cubejs/cubejs-api/v1',
    },
};
