<template>
    <v-select
        :label="getTrad('timeDimensions')"
        outlined
        hide-details
        :value="timeDimensions[0] && timeDimensions[0].dimension.name"
        :items="availableTimeDimensions"
        clearable
        item-text="shortTitle"
        item-value="name"
        @change="handleTimeChange" />
</template>
<script>
    import getTrad from '../../i18n/getTrad';

    export default {
        name: 'TimeDimensionSelect.vue',
        mixins: [getTrad],
        props: {
            timeDimensions: {
                default: () => [],
            },
            availableTimeDimensions: {
                default: () => [],
            },
        },
        methods: {
            handleTimeChange(value) {
                const [selectedTd = {}] = this.timeDimensions;
                const td = this.availableTimeDimensions.find(({ name }) => name === value);
                if (!td) {
                    this.$emit('change', []);
                    return;
                }
                this.$emit('change', [
                    {
                        dimension: td.name,
                        granularity: selectedTd.granularity || 'day',
                        dateRange: selectedTd.dateRange,
                    },
                ]);
            },
        },
    };
</script>
<style scoped></style>
