<template>
    <v-row>
        <v-col v-for="(item, index) in items" :key="index" cols="6" sm="6" md="4" lg="3">
            <span class="font-weight-bold text--black text-lg-body-1 text-body-2">
                {{ item[0].toUpperCase() + ' - ' }}
            </span>
            <span class="text--black text-lg-body-1 text-body-2">
                {{ item[1] }}
            </span>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: 'OrderCard',
        props: {
            title: String,
            resultSet: Object,
        },
        data() {
            return {
                items: this.resultSet.rawData().map((item) => Object.values(item).map((element) => element)),
            };
        },
    };
</script>

<style scoped></style>
