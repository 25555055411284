<template>
    <v-container>
        <v-card class="my-12" max-width="2200">
            <v-card-title class="text-lg-h5 text-h6 justify-center font-weight-bold"> Total de atendimentos por serviços</v-card-title>
            <v-card-text class="pt-4">
                <query-builder :cubejs-api="useCube" :query="TotalAtendQueue">
                    <template v-slot="{ loading, resultSet }">
                        <div v-if="loading" class="loading">Loading...</div>
                        <div class="div" v-if="!loading && resultSet !== undefined">
                            <OrderCard1 :resultSet="resultSet" />
                        </div>
                    </template>
                </query-builder>
            </v-card-text>
        </v-card>
        <v-row>
            <v-col cols="6">
                <v-card class="mx-auto my-12" max-width="2500">
                    <v-card-title class="text-lg-h5 text-h6 justify-center font-weight-bold"> Total de trabalhadores por ano</v-card-title>
                    <v-card-text class="pt-4">
                        <query-builder :cubejs-api="useCube" :query="TotalWorkersYear">
                            <template v-slot="{ loading, resultSet }">
                                <div v-if="loading" class="loading">Loading...</div>
                                <div class="div" v-if="!loading && resultSet !== undefined">
                                    <ChartRenderer v-if="!loading" chartType="pie" :resultSetSerialized="resultSet"></ChartRenderer>
                                </div>
                            </template>
                        </query-builder>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="6">
                <v-card class="mx-auto my-12" max-width="2200">
                    <v-card-title class="text-lg-h5 text-h6 justify-center font-weight-bold"> Média temporal de atendimento por serviço </v-card-title>
                    <v-card-text class="pt-4">
                        <query-builder :cubejs-api="useCube" :query="MeanAttendanceTimeService">
                            <template v-slot="{ loading, resultSet }">
                                <div v-if="loading" class="loading">Loading...</div>
                                <div v-if="!loading && resultSet !== undefined">
                                    <!-- <BarChart2 v-if="!loading" :key="'barchart-' + windowWidth" :resultSet="resultSet" /> -->
                                    <ChartRenderer v-if="!loading" chartType="line" :resultSetSerialized="resultSet"></ChartRenderer>
                                </div>
                            </template>
                        </query-builder>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-card class="mx-auto my-12" max-width="2200">
            <v-card-title class="text-lg-h5 text-h6 justify-center font-weight-bold"> Tempo de espera mensal do cliente nos diferentes serviços </v-card-title>
            <v-card-text class="pt-4">
                <query-builder :cubejs-api="useCube" :query="WaitingTimeYearly">
                    <template v-slot="{ loading, resultSet }">
                        <div v-if="loading" class="loading">Loading...</div>
                        <div v-if="!loading && resultSet !== undefined">
                            <!-- <ChartRenderer v-if="!loading" chartType="line" :resultSet="resultSet"></ChartRenderer> -->
                            <!-- <AreaChart v-if="!loading" :key="'areachart-' + windowWidth" :resultSet="resultSet" /> -->
                            <ChartRenderer v-if="!loading" chartType="scatter" :resultSetSerialized="resultSet"></ChartRenderer>
                        </div>
                    </template>
                </query-builder>
            </v-card-text>
        </v-card>
        <v-card class="mx-auto my-12" max-width="2200">
            <v-card-title class="text-lg-h5 text-h6 justify-center font-weight-bold"> Média temporal de atendimentos por empregado mensalmente em 2022 </v-card-title>
            <v-card-text class="pt-4">
                <query-builder :cubejs-api="useCube" :query="MeanAttendanceTime">
                    <template v-slot="{ loading, resultSet }">
                        <div v-if="loading" class="loading">Loading...</div>
                        <div v-if="!loading && resultSet !== undefined">
                            <!-- <BarChart2 v-if="!loading" :key="'barchart-' + windowWidth" :resultSet="resultSet" /> -->
                            <ChartRenderer v-if="!loading" chartType="bar" :resultSetSerialized="resultSet"></ChartRenderer>
                        </div>
                    </template>
                </query-builder>
            </v-card-text>
        </v-card>
        <v-row>
            <v-col cols="6">
                <v-card class="mx-auto my-12" max-width="2200">
                    <v-card-title class="text-lg-h5 text-h6 justify-center font-weight-bold"> Taxa de ocupação anual dos empregados em 2022 </v-card-title>
                    <v-card-text class="pt-4">
                        <query-builder :cubejs-api="useCube" :query="PercentLotEmployee">
                            <template v-slot="{ loading, resultSet }">
                                <div v-if="loading" class="loading">Loading...</div>
                                <div class="div" v-if="!loading && resultSet !== undefined">
                                    <!-- <ChartRenderer v-if="!loading" chartType="line" :resultSetSerialized="resultSet"></ChartRenderer> -->
                                    <OrderCard :resultSet="resultSet" />
                                </div>
                            </template>
                        </query-builder>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="6">
                <v-card class="mx-auto my-12" max-width="2200">
                    <v-card-title class="text-lg-h5 text-h6 justify-center font-weight-bold"> Total de atendimentos por empregado em 2022 </v-card-title>
                    <v-card-text class="pt-4">
                        <query-builder :cubejs-api="useCube" :query="TotalAtend">
                            <template v-slot="{ loading, resultSet }">
                                <div v-if="loading" class="loading">Loading...</div>
                                <div v-if="!loading && resultSet !== undefined">
                                    <!-- <BarChart3 v-if="!loading" :key="'barchart-' + windowWidth" :resultSet="resultSet" /> -->
                                    <ChartRenderer v-if="!loading" chartType="heatmap" :resultSetSerialized="resultSet"></ChartRenderer>
                                </div>
                            </template>
                        </query-builder>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-card class="mx-auto my-12" max-width="2200">
            <v-card-title class="text-lg-h5 text-h6 justify-center font-weight-bold"> Total de atendimentos por dia da semana e hora em 2021 </v-card-title>
            <v-card-text class="pt-4">
                <query-builder :cubejs-api="useCube" :query="TAtend2022">
                    <template v-slot="{ loading, resultSet }">
                        <div v-if="loading" class="loading">Loading...</div>
                        <div v-if="!loading && resultSet !== undefined">
                            <!-- <LineChart2 v-if="!loading" :key="'linechart-' + windowWidth" :resultSet="resultSet" /> -->
                            <ChartRenderer v-if="!loading" chartType="line" :resultSetSerialized="resultSet"></ChartRenderer>
                        </div>
                    </template>
                </query-builder>
            </v-card-text>
        </v-card>
        <v-row>
            <v-col cols="6">
                <v-card class="mx-auto my-12" max-width="2200">
                    <v-card-title class="text-lg-h5 text-h6 justify-center font-weight-bold"> Total de tempo de pausas dos empregados no ano 2022 no serviço Fármacia </v-card-title>
                    <v-card-text class="pt-4">
                        <query-builder :cubejs-api="useCube" :query="PausePharma">
                            <template v-slot="{ loading, resultSet }">
                                <div v-if="loading" class="loading">Loading...</div>
                                <div v-if="!loading && resultSet !== undefined">
                                    <!-- <LineChart2 v-if="!loading" :key="'linechart-' + windowWidth" :resultSet="resultSet" /> -->
                                    <ChartRenderer v-if="!loading" chartType="radar" :resultSetSerialized="resultSet"></ChartRenderer>
                                </div>
                            </template>
                        </query-builder>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="6">
                <v-card class="mx-auto my-12" max-width="2200">
                    <v-card-title class="text-lg-h5 text-h6 justify-center font-weight-bold"> Total de tickets por estado gerados em cada serviço </v-card-title>
                    <v-card-text class="pt-4">
                        <query-builder :cubejs-api="useCube" :query="Totaltickets">
                            <template v-slot="{ loading, resultSet }">
                                <div v-if="loading" class="loading">Loading...</div>
                                <div v-if="!loading && resultSet !== undefined">
                                    <!-- <BarChart3 v-if="!loading" :key="'barchart-' + windowWidth" :resultSet="resultSet" /> -->
                                    <ChartRenderer v-if="!loading" chartType="heatmap" :resultSetSerialized="resultSet"></ChartRenderer>
                                </div>
                            </template>
                        </query-builder>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    // import cubejs from '@cubejs-client/core';
    import { QueryBuilder } from '@cubejs-client/vue';
    import Card from '../components/KPIs/card.vue';
    import ChartRenderer from '../components/Explore/ChartRenderer.vue';
    import OrderCard from '../components/KPIs/OrderCard';
    import OrderCard1 from '../components/KPIs/OrderCard1.vue';
    import GoogleMaps from '../components/KPIs/GoogleMaps.vue';
    import useCube from '../utils/useCube';

    export default {
        name: 'KPIs',
        components: {
            QueryBuilder,
            ChartRenderer,
            Card,
            OrderCard,
            GoogleMaps,
            OrderCard1,
        },
        data(vm) {
            // get current route

            return {
                useCube: useCube(vm.$route.params.project),
                windowWidth: 0,
                cardHeight: '550px',
                TotalAtendQueue: {
                    measures: ['FactsTableAttendance.TotalAttendance'],
                    dimensions: ['DimQueue.queueName'],
                },
                TotalWorkersYear: {
                    measures: ['FactsTableAttendance.TotalWorkers'],
                    dimensions: ['DimCalendar.year'],
                },
                MeanAttendanceTime: {
                    measures: ['FactsTableAttendance.MeanAttendanceTime'],
                    dimensions: ['DimEmployee.code'],
                    timeDimensions: [
                        {
                            dimension: 'DimCalendar.date',
                            granularity: 'month',
                        },
                    ],
                    filters: [
                        {
                            member: 'DimCalendar.year',
                            operator: 'equals',
                            values: ['2022'],
                        },
                    ],
                },
                MeanAttendanceTimeService: {
                    measures: ['FactsTableAttendance.MeanAttendanceTime'],
                    dimensions: ['DimLocal.name'],
                    timeDimensions: [
                        {
                            dimension: 'DimCalendar.date',
                            granularity: 'year',
                        },
                    ],
                },
                PausePharma: {
                    measures: ['FactsTableAttendance.TotalDownTimeEmployee'],
                    dimensions: ['DimEmployee.code'],
                    timeDimensions: [
                        {
                            dimension: 'DimCalendar.date',
                            granularity: 'month',
                        },
                    ],
                    filters: [
                        {
                            member: 'DimLocal.name',
                            operator: 'equals',
                            values: ['Farmácia Demo'],
                        },
                        {
                            member: 'DimCalendar.year',
                            operator: 'equals',
                            values: ['2022'],
                        },
                    ],
                },

                WaitingTimeYearly: {
                    measures: ['FactsTableAttendance.WaitingTime'],
                    dimensions: ['DimLocal.name'],
                    timeDimensions: [
                        {
                            dimension: 'DimCalendar.date',
                            granularity: 'month',
                        },
                    ],
                    // filters: [
                    //     {
                    //         member: 'DimCalendar.year',
                    //         operator: 'equals',
                    //         values: ['2021'],
                    //     },
                    // ],
                },
                Totaltickets: {
                    measures: ['FactsTableAttendance.TotalTickets'],
                    dimensions: ['DimLocal.businessSector', 'DimFinishreason.state'],
                    timeDimensions: [
                        {
                            dimension: 'DimCalendar.date',
                            granularity: 'year',
                        },
                    ],
                    order: [
                        ['DimLocal.businessSector', 'asc'],

                        ['DimCalendar.date', 'asc'],
                    ],
                },
                PercentLotEmployee: {
                    measures: ['FactsTableAttendance.OccupancyRateEmployee'],
                    dimensions: ['DimEmployee.code'],
                    filters: [
                        {
                            member: 'DimCalendar.year',
                            operator: 'equals',
                            values: ['2022'],
                        },
                    ],
                    order: [['DimEmployee.code', 'asc']],
                },
                TotalAtend: {
                    measures: ['FactsTableAttendance.TotalAttendance'],
                    dimensions: ['DimEmployee.code'],
                    filters: [
                        {
                            member: 'DimCalendar.year',
                            operator: 'equals',
                            values: ['2022'],
                        },
                    ],
                    timeDimensions: [
                        {
                            dimension: 'DimCalendar.date',
                            granularity: 'year',
                        },
                    ],
                },
                TAtend2022: {
                    measures: ['FactsTableAttendance.TotalAttendance'],
                    dimensions: ['DimCalendar.day'],
                    timeDimensions: [
                        {
                            dimension: 'DimTime.fulltime',
                            granularity: 'hour',
                        },
                    ],
                    filters: [
                        {
                            member: 'DimCalendar.year',
                            operator: 'equals',
                            values: ['2021'],
                        },
                    ],
                },
            };
        },
        methods: {
            increaseHeight() {
                this.cardHeight = '1000px';
            },
        },
    };
</script>

<style>
    .background {
        background: #f3f3fb;
    }
    .dashboard {
        display: flex;
        justify-content: space-evenly;
        gap: 1rem;
        flex-wrap: wrap;
        align-items: left;
    }
    .cards-container {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        gap: 1rem;
        flex-wrap: wrap;
        align-items: center;
    }

    /* .card-wrapper {
        display: flex;
        margin-bottom: 40px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: fit-content;
        min-width: 250px;
        min-height: 100px;
        padding: 2rem;
        border-radius: 10px;
        background-color: #fafafa;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    } */
    /* .card-title {
        font-size: 1.5rem;
        font-weight: bold;
        margin: 0;
    } */
    .card-content {
        font-size: 2rem;
        /* font-weight: bold; */
        color: #5393ca;
        padding-top: 6px;
    }

    rect {
        fill: white;
    }
</style>
