import _ from 'lodash';
import { ResultSet } from '@cubejs-client/core';
import { dateToGranularity, useCustomColors, orderTablePivot, defaultOptions, defaultInitOptions } from './utils';
import { gradientColor } from '../constants/colors';

const useHeatmapChart = (resultSet, _options = {}) => {
    const result = resultSet instanceof ResultSet ? resultSet : ResultSet.deserialize(resultSet);
    // console.log('chartPivot', result.chartPivot());
    // console.log('query', result.query());
    // console.log('series', result.series());
    // console.log('tablePivot', result.tablePivot());
    // console.log('tableColumns', result.tableColumns());
    // console.log('pivot', result.pivot());
    // console.log('seriesNames', result.seriesNames());
    // console.log('categories', result.categories());

    const timeDimension = _.get(result.query(), 'timeDimensions[0].dimension', null);
    const granularity = _.get(result.query(), 'timeDimensions[0].granularity', null);
    const xAxis = result.categories().map((c) => dateToGranularity(c.x, granularity));

    const dimensions = _.get(result.query(), 'dimensions', []).map((d) => {
        return result.tableColumns().find((c) => c.key == d);
    });
    const measures = _.get(result.query(), 'measures', []).map((m) => {
        return result.tableColumns().find((c) => c.key == m);
    });
    const tableSorted = orderTablePivot(result.tablePivot(), result.query());
    const yAxis = [
        ...new Set(
            tableSorted.map((r) => {
                return dimensions.map((d) => r[d.key]).join(' / ');
            }),
        ),
    ];

    const series = [];

    // a serie item must be an array of [x, y, value]
    tableSorted.forEach((r) => {
        measures.forEach((m) => {
            let x = xAxis.indexOf(dateToGranularity(r[`${timeDimension}.${granularity}`], granularity));
            let y = yAxis.indexOf(dimensions.map((d) => r[d.key]).join(' / '));
            let value = r[m.key];
            series.push([x, y, value]);
        });
    });

    let max = tableSorted.reduce((max, r) => {
        measures.forEach((m) => {
            if (r[m.key] > max) {
                max = r[m.key];
            }
        });
        return max;
    }, 0);

    let min = tableSorted.reduce((min, r) => {
        measures.forEach((m) => {
            if (r[m.key] < min) {
                min = r[m.key];
            }
        });
        return min;
    }, 0);

    let options = {
        legend: {
            show: false,
        },
        tooltip: {
            trigger: 'item',
            position: 'top',
        },
        grid: {
            height: '70%',
            top: '10%',
        },
        gradientColor: gradientColor,
        xAxis: {
            type: 'category',
            splitArea: {
                show: true,
            },
            data: xAxis,
            name: granularity,
        },
        yAxis: {
            type: 'category',
            data: yAxis,
            splitArea: {
                show: true,
            },
            name: dimensions.map((d) => d.title).join(' / '),
        },
        visualMap: {
            min,
            max,
            calculable: true,
            orient: 'horizontal',
            left: 'center',
            bottom: '10%',
        },
        series: [
            {
                name: measures.map((m) => m.title).join(' / '),
                type: 'heatmap',
                data: series,
                label: {
                    show: true,
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowColor: 'rgba(0, 0, 0, 0.5)',
                    },
                },
            },
        ],
    };

    const initOptions = {};

    return {
        result,
        customOptions: _.merge(options, defaultOptions, _options),
        initOptions: _.merge(initOptions, defaultInitOptions),
    };
};

export default useHeatmapChart;
