export default {
    explore: 'Explorar',
    measures: 'Factos',
    dimensions: 'Dimensões',
    timeDimensions: 'Dimensões Temporais',
    granularity: 'Granularidade',
    granularities: {
        undefined: 'Nenhuma',
        second: 'Segundo',
        minute: 'Minuto',
        hour: 'Hora',
        day: 'Dia',
        week: 'Semana',
        month: 'Mês',
        quarter: 'Trimestre',
        year: 'Ano',
    },
};
