<template>
    <v-container fluid class="text-center background pa-0">
        <query-builder :cubejs-api="useCube" css="width: 100%">
            <template #builder="{ availableMeasures, availableDimensions, filters, setFilters }">
                <v-container fluid class="pa-4 pa-md-8 pt-6 background-white">
                    <FilterComponent
                        :filters="filters"
                        :dimensions="availableDimensions.map((i) => ({ value: i.name, text: i.shortTitle }))"
                        :measures="availableMeasures.map((i) => ({ value: i.name, text: i.shortTitle }))"
                        :setFilters="setFilters"
                        v-on:save="updateChartsFilters($event)"
                        v-on:removeFilter="removeChartsFilters($event)">
                    </FilterComponent>
                    <v-switch v-model="isDraggable" color="blue" :label="`Is Draggable`"></v-switch>
                </v-container>
            </template>

            <grid-layout v-if="layout.length" :layout="layout" :col-num="colNum" :row-height="20" :vertical-compact="true" :use-css-transforms="true" @layout-updated="layoutUpdatedEvent">
                <grid-item v-for="item in layout" :key="item.i" :x="item.x" :y="item.y" :w="item.w" :h="item.h" :i="item.i" :isDraggable="isDraggable">
                    <query-renderer class="height-100" :cubejs-api="useCube" :query="getQueryById(item.i)">
                        <template #default="{ resultSet }">
                            <v-card :loading="!resultSet" class="px-4 py-2" min-height="100%">
                                <div class="flex">
                                    <v-card-title>{{ item.value.name }}</v-card-title>
                                    <v-card-actions class="right flex-column align-end" v-if="BackClicked == false">
                                        <pre v-if="item.value.initialVizState !== item.value.vizState">
                                        PREVIOUS DEGREE: {{ Atrib[item.value.id] }} - {{ DataClicked[item.value.id] }}
                                        CURRENT DEGREE: {{ item.value.grau[0] + item.value.grau.substring(1) }}</pre
                                        >
                                    </v-card-actions>
                                    <v-card-actions class="right flex-column align-end" v-if="BackClicked == true">
                                        <pre v-if="item.value.initialVizState !== item.value.vizState">
                                        PREVIOUS DEGREE: {{ Atrib[item.value.id] }}
                                        CURRENT DEGREE: {{ item.value.grau[0] + item.value.grau.substring(1) }}</pre
                                        >
                                    </v-card-actions>
                                </div>
                                <v-card-actions>
                                    <v-btn v-if="item.value.initialVizState !== item.value.vizState" plain color="error" @click="intermedio(item)"> Back </v-btn>
                                </v-card-actions>

                                <v-card-actions> <v-btn v-if="item.value.initialVizState !== item.value.vizState" plain color="error" @click="resetChart(item)"> Reset </v-btn></v-card-actions>

                                <template v-if="resultSet">
                                    <ChartRenderer :resultSetSerialized="resultSet" :chart-type="item.value.type" v-on:change="change($event, item)" ref="chart"></ChartRenderer>
                                </template>
                                <v-card-actions>
                                    <v-btn plain color="error" @click="deleteDashboardItem(item.i)"> Delete </v-btn>
                                </v-card-actions>
                                <template slot="progress">
                                    <v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
                                </template>
                            </v-card>
                        </template>
                    </query-renderer>
                </grid-item>
            </grid-layout>
        </query-builder>
    </v-container>
</template>

<script>
    import { UPDATE_DASHBOARD_ITEM, DELETE_DASHBOARD_ITEM } from '../../graphql/mutations';
    import { QueryRenderer, QueryBuilder } from '@cubejs-client/vue';
    import VueGridLayout from 'vue-grid-layout';
    import ChartRenderer from '../Explore/ChartRenderer.vue';
    import FilterComponent from '../Explore/FilterComponent.vue';
    import gql from 'graphql-tag';
    import moment from 'moment';
    import _ from 'lodash';
    import useCube from '../../utils/useCube';
    import cubeConfig from '../../constants/cube';

    export default {
        name: 'GridPage',
        props: {
            dashboardItems: {
                type: Array,
                required: true,
            },
        },
        components: {
            ChartRenderer,
            FilterComponent,
            QueryBuilder,
            QueryRenderer,
            GridLayout: VueGridLayout.GridLayout,
            GridItem: VueGridLayout.GridItem,
        },
        data(vm) {
            return {
                useCube: useCube(vm.$route.params.project),
                isrollup: false,
                isdrildown: false,
                beforeQuery: {},
                DataClicked: {},
                DataAux: '',
                DataDay: '',
                Atrib: {},
                BackClicked: false,
                endDrillDownLocal: false,
                endDrillDownTime: false,
                layout: [],
                colNum: 12,
                query: {},
                isDraggable: false,
                domain: cubeConfig[vm.$route.params.project].API_URL,
            };
        },
        watch: {
            dashboardItems: {
                deep: true,
                handler() {
                    this.updateLayout();
                },
            },
        },
        beforeMount() {
            this.updateLayout();
        },
        methods: {
            updateChartsFilters(newFilters) {
                let items = this.dashboardItems;
                // let member = JSON.parse(item.value.vizState).query;
                for (let i = 0; i < newFilters.length; i++) {
                    let member = newFilters[i].member;
                    let operator = newFilters[i].operator;
                    let values = newFilters[i].values[0];

                    items.forEach((i) => {
                        let viz = JSON.parse(i.vizState);
                        //viz.query get query
                        if (_.has(viz.query, 'filters')) {
                            viz.query.filters.push({
                                member: member,
                                operator: operator,
                                values: [values],
                            });
                        } else {
                            //se nao tiver filtro
                            viz.query.filters = [
                                {
                                    member: member,
                                    operator: operator,
                                    values: [values],
                                },
                            ];
                        }

                        this.query = JSON.stringify(viz);

                        this.$apollo.mutate({
                            mutation: gql`
                                mutation updateDashboardItem($id: String!, $input: DashboardItemInput) {
                                    updateDashboardItem(id: $id, input: $input) {
                                        id
                                        layout
                                        vizState
                                        name
                                        type
                                        grau
                                    }
                                }
                            `,
                            variables: {
                                id: i.id,
                                input: {
                                    layout: '',
                                    vizState: this.query,
                                    name: i.name,
                                    type: i.type,
                                    grau: i.grau,
                                },
                            },
                        });
                    });
                }
            },

            removeChartsFilters(filterRemoved) {
                let items = this.dashboardItems;

                items.forEach((j) => {
                    //para cada grafico da dashboard
                    let viz = JSON.parse(j.vizState);
                    let i = 0;
                    if (viz.query.filters.length == 1) {
                        //apagar elem filters todo
                        let arr = viz.query;
                        const namee = 'filters';
                        let { [namee]: removedProperty, ...semFiltro } = arr;

                        viz.query = semFiltro;
                    } else if (viz.query.filters.length > 1) {
                        while (viz.query.filters !== 'undefined' && viz.query.filters.length > i) {
                            if (
                                // comparar cada filtro existente com o filtro aplicado
                                viz.query.filters[i].member == filterRemoved[0].member.name &&
                                viz.query.filters[i].operator == filterRemoved[0].operator &&
                                viz.query.filters[i].values[0] == [filterRemoved[0].values[0]][0]
                            ) {
                                if (viz.query.filters.length > 1) {
                                    viz.query.filters.splice(i, 1);
                                }
                            }
                            i++;
                        } // end whild
                    }
                    this.query = JSON.stringify(viz);

                    //update charts
                    this.$apollo.mutate({
                        mutation: gql`
                            mutation updateDashboardItem($id: String!, $input: DashboardItemInput) {
                                updateDashboardItem(id: $id, input: $input) {
                                    id
                                    layout
                                    vizState
                                    name
                                    type
                                    grau
                                }
                            }
                        `,
                        variables: {
                            id: j.id,
                            input: {
                                layout: '',
                                vizState: this.query,
                                name: j.name,
                                type: j.type,
                                grau: i.grau,
                            },
                        },
                    });

                    // } // end if !== undefined
                }); //forEach
            },

            getQueryById(id) {
                let item = this.dashboardItems.filter((item) => +item.id === id);
                return JSON.parse(item[0].vizState).query;
            },

            // drillDownLocal(item, DataClicked) {
            //     this.BackClicked = false;
            //     let dimension = JSON.parse(item.value.vizState).query.dimensions;
            //     let member = JSON.parse(item.value.vizState).query;
            //     let viz = JSON.parse(item.value.vizState);

            //     fetch(`${this.domain}/auxiliarLocal`).then(async (response) => {
            //         const data = await response.json();
            //         let grau;

            //         Object.keys(data).forEach((key) => {
            //             if (data[key].Dimensao == dimension) {
            //                 grau = data[key].Grau;
            //                 this.Atrib[item.value.id] = data[key].Atributo[0].toUpperCase() + data[key].Atributo.substring(1);

            //                 if (_.has(viz.query, 'filters')) {
            //                     //se tiver filtro -->
            //                     viz.query.filters.push({
            //                         member: dimension.toString(),
            //                         operator: 'equals',
            //                         values: [DataClicked],
            //                     });
            //                 } else {
            //                     //se nao tiver filtro
            //                     viz.query.filters = [
            //                         {
            //                             member: dimension.toString(),
            //                             operator: 'equals',
            //                             values: [DataClicked],
            //                         },
            //                     ];
            //                 }
            //             }
            //         }),
            //             (grau = grau - 1);
            //         if (grau < 1) {
            //             this.$alert('End of drilldown! If you want click back to previous chart! Otherwise Reset button to return to the initial chart!');
            //             return (this.endDrillDownLocal = true);
            //         }

            //         fetch(`${this.domain}/local/${grau}`).then(async (response2) => {
            //             const data2 = await response2.json();

            //             item.value.grau = data2.Atributo;

            //             this.DataClicked[item.value.id] = DataClicked;
            //             viz.query.dimensions = [data2.Dimensao];
            //             this.query = JSON.stringify(viz);

            //             this.$apollo.mutate({
            //                 mutation: gql`
            //                     mutation updateDashboardItem($id: String!, $input: DashboardItemInput) {
            //                         updateDashboardItem(id: $id, input: $input) {
            //                             id
            //                             layout
            //                             vizState
            //                             name
            //                             type
            //                             grau
            //                         }
            //                     }
            //                 `,
            //                 variables: {
            //                     id: item.i.toString(),
            //                     input: {
            //                         layout: '',
            //                         vizState: this.query,
            //                         name: item.value.name,
            //                         type: item.value.type,
            //                         grau: item.value.grau,
            //                     },
            //                 },
            //             });
            //         });
            //     });
            //     this.isdrildown = true;
            //     this.BackClicked = false;
            //     this.isrollup = false;
            // },

            async drillDownTime(item, clicked, DataClicked) {
                let atribute = JSON.parse(item.value.vizState).query.timeDimensions[0].granularity;

                var myHeaders = new Headers();
                myHeaders.append('Content-Type', 'application/json');

                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow',
                };
                //fetch para ir buscar o grau e o formato do gráfico original à tabela auxiliar
                await fetch(`${this.domain}/auxiliarGrau/${atribute}`, requestOptions)
                    .then(async (response) => {
                        const data = await response.json();

                        let grau = data[0].grau;
                        let viz = JSON.parse(item.value.vizState); //all info

                        let formato = data[0].formato;

                        if (grau == 5) {
                            //quarter
                            let Auxiliar = DataClicked.split('-');
                            DataClicked = Auxiliar[1];
                            this.dataAux = Auxiliar[0];
                        } else if (grau == 4) {
                            //month
                            let Auxiliar = DataClicked.split('-');
                            DataClicked = moment(Auxiliar[1]).format(formato);
                            this.dataAux = Auxiliar[0];
                        } else if (grau == 3) {
                            //week
                            let Auxiliar = DataClicked.split('-');
                            DataClicked = Auxiliar[1].slice(1);
                            this.dataAux = Auxiliar[0];
                        } else {
                            //year and day and hour
                            DataClicked = moment(DataClicked).format(formato);
                        }

                        let member = JSON.parse(item.value.vizState).query;

                        if (this.isdrildown == false) {
                            if (_.has(member, 'filters')) {
                                //se tiver filtro --> add
                                this.$alert(
                                    'REMEMBER: This chart has a filter of " ' +
                                        ' ' +
                                        viz.query.filters[0].member.split('.')[1] +
                                        ' ' +
                                        viz.query.filters[0].operator +
                                        ' ' +
                                        viz.query.filters[0].values[0] +
                                        ' ' +
                                        ' " !',
                                );
                                viz.query.filters.push({
                                    member: member.dimensions.toString(),
                                    operator: 'equals',
                                    values: [clicked],
                                });
                            } else {
                                //se nao tiver filtro
                                viz.query.filters = [
                                    {
                                        member: member.dimensions.toString(),
                                        operator: 'equals',
                                        values: [clicked],
                                    },
                                ];
                            }
                        }
                        //consoante o grau vai buscar dimensao e filtro da tabela auxiliar
                        await fetch(`${this.domain}/auxiliar/${grau}`, requestOptions).then(async (response2) => {
                            const data4 = await response2.json();
                            this.Atrib[item.value.id] = data4[0].atributo[0].toUpperCase() + data4[0].atributo.substring(1);
                            this.DataClicked[item.value.id] = DataClicked;

                            if (grau == 5) {
                                viz.query.filters.push(
                                    {
                                        member: 'DimCalendar.year',
                                        operator: 'equals',
                                        values: [this.dataAux],
                                    },
                                    {
                                        member: data4[0].filter,
                                        operator: 'equals',
                                        values: [DataClicked],
                                    },
                                );
                            } else if (grau == 4) {
                                viz.query.filters.push(
                                    {
                                        member: 'DimCalendar.year',
                                        operator: 'equals',
                                        values: [this.dataAux],
                                    },
                                    {
                                        member: data4[0].filter,
                                        operator: 'equals',
                                        values: [DataClicked],
                                    },
                                );
                            } else if (grau == 3) {
                                viz.query.filters.push(
                                    {
                                        member: 'DimCalendar.year',
                                        operator: 'equals',
                                        values: [this.dataAux],
                                    },
                                    {
                                        member: data4[0].filter,
                                        operator: 'equals',
                                        values: [DataClicked],
                                    },
                                );
                            } else {
                                viz.query.filters.push({
                                    member: data4[0].filter,
                                    operator: 'equals',
                                    values: [DataClicked],
                                });
                            }

                            grau = grau - 1;
                            if (grau < 1) {
                                this.$alert('End of drilldown! If you want click back to previous chart! Otherwise Reset button to return to the initial chart!');
                                return (this.endDrillDownTime = true);
                            }

                            //buscar a dimensao, filtro e atributo ja do grau inferior
                            await fetch(`${this.domain}/auxiliar/${grau}`, requestOptions).then(async (response2) => {
                                const data2 = await response2.json();

                                item.value.grau = data2[0].atributo;

                                viz.query.timeDimensions[0].dimension = data2[0].dimensao;
                                viz.query.timeDimensions[0].granularity = data2[0].atributo;
                                this.query = JSON.stringify(viz);
                                this.$apollo.mutate({
                                    mutation: gql`
                                        mutation updateDashboardItem($id: String!, $input: DashboardItemInput) {
                                            updateDashboardItem(id: $id, input: $input) {
                                                id
                                                layout
                                                vizState
                                                name
                                                type
                                                grau
                                            }
                                        }
                                    `,
                                    variables: {
                                        id: item.i.toString(),
                                        input: {
                                            layout: '',
                                            vizState: this.query,
                                            name: item.value.name,
                                            type: item.value.type,
                                            grau: item.value.grau,
                                        },
                                    },
                                });
                            });
                        });
                    }) // end of fetch
                    .catch((error) => console.log('error', error));
                this.isdrildown = true;
                this.isrollup = false;
                this.BackClicked = false;
            },

            //função reset
            resetChart(item) {
                this.isdrildown = false;
                this.endDrillDownLocal = false;
                this.endDrillDownTime = false;
                this.DataClicked = {};
                this.Atrib = {};
                this.$apollo.mutate({
                    mutation: gql`
                        mutation updateDashboardItem($id: String!, $input: DashboardItemInput) {
                            updateDashboardItem(id: $id, input: $input) {
                                id
                                layout
                                vizState
                                name
                                type
                                grau
                            }
                        }
                    `,
                    variables: {
                        id: item.i.toString(),
                        input: {
                            layout: '',
                            vizState: item.value.initialVizState,
                            name: item.value.name,
                            type: item.value.type,
                            grau: ' ',
                        },
                    },
                });
            },

            // async Rolluplocal(item) {
            //     this.BackClicked = true;
            //     this.isdrildown = false;
            //     let dimension = JSON.parse(item.value.vizState).query.dimensions;
            //     let viz = JSON.parse(item.value.vizState);

            //     await fetch(`${this.domain}/auxiliarLocal`).then(async (response) => {
            //         const data = await response.json();

            //         let grau;

            //         Object.keys(data).forEach((key) => {
            //             if (data[key].Dimensao == dimension) {
            //                 grau = data[key].Grau;
            //                 this.Atrib[item.value.id] = data[key].Atributo[0].toUpperCase() + data[key].Atributo.substring(1);
            //             }
            //         });

            //         grau = grau + 1;
            //         if (grau > 4) {
            //             this.$alert('End of rollup!');
            //         }

            //         await fetch(`${this.domain}/local/${grau}`).then(async (response2) => {
            //             const data2 = await response2.json();

            //             if (viz.query.filters.length == 1) {
            //                 //apagar elem filters todo
            //                 let arr = viz.query;
            //                 const namee = 'filters';
            //                 let { [namee]: removedProperty, ...semFiltro } = arr;
            //                 console.log(removedProperty);
            //                 viz.query = semFiltro;
            //             } else {
            //                 viz.query.filters.pop();
            //             }

            //             item.value.grau = data2.Atributo;

            //             viz.query.dimensions = [data2.Dimensao];
            //             this.query = JSON.stringify(viz);

            //             this.$apollo.mutate({
            //                 mutation: gql`
            //                     mutation updateDashboardItem($id: String!, $input: DashboardItemInput) {
            //                         updateDashboardItem(id: $id, input: $input) {
            //                             id
            //                             layout
            //                             vizState
            //                             name
            //                             type
            //                             grau
            //                         }
            //                     }
            //                 `,
            //                 variables: {
            //                     id: item.i.toString(),
            //                     input: {
            //                         layout: '',
            //                         vizState: this.query,
            //                         name: item.value.name,
            //                         type: item.value.type,
            //                         grau: item.value.grau,
            //                     },
            //                 },
            //             });
            //         });
            //     });
            // },

            RollupTime(item) {
                this.BackClicked = true;
                let atribute = JSON.parse(item.value.vizState).query.timeDimensions[0].granularity;
                var myHeaders = new Headers();
                myHeaders.append('Content-Type', 'application/json');

                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow',
                };

                fetch(`${this.domain}/auxiliarGrau/${atribute}`, requestOptions).then(async (response) => {
                    const data = await response.json();

                    let grau = data[0].grau;
                    let viz = JSON.parse(item.value.vizState); //all info

                    fetch(`${this.domain}/auxiliar/${grau}`, requestOptions).then(async (response2) => {
                        const data4 = await response2.json();

                        this.Atrib[item.value.id] = data4[0].atributo;
                        grau++;

                        fetch(`${this.domain}/auxiliar/${grau}`, requestOptions).then(async (response2) => {
                            const data2 = await response2.json();

                            // if (viz.query.filters.length == 2) {
                            //     //apagar elem filters todo
                            //     console.log(viz.query.filters.length);
                            //     let arr = viz.query;
                            //     const namee = 'filters';
                            //     let { [namee]: removedProperty, ...semFiltro } = arr;
                            //     console.log(removedProperty);
                            //     viz.query = semFiltro;
                            //     console.log(viz.query);
                            //     this.isdrildown = false;
                            // }
                            if (grau == 2 && viz.query.filters.length != 2) {
                                viz.query.filters.pop();
                            } else if (grau != 6 && grau != 2 && viz.query.filters.length == 3) {
                                delete viz.query.filters;
                                this.isdrildown = false;
                            } else {
                                //apagar ultimos 2 filtros

                                viz.query.filters.splice(-2, 2);
                                if (viz.query.filters.length == 0) {
                                    delete viz.query.filters;
                                    this.isdrildown = false;
                                }
                            }
                            item.value.grau = data2[0].atributo;

                            viz.query.timeDimensions[0].dimension = data2[0].dimensao;
                            viz.query.timeDimensions[0].granularity = data2[0].atributo;
                            this.query = JSON.stringify(viz);

                            this.$apollo.mutate({
                                mutation: gql`
                                    mutation updateDashboardItem($id: String!, $input: DashboardItemInput) {
                                        updateDashboardItem(id: $id, input: $input) {
                                            id
                                            layout
                                            vizState
                                            name
                                            type
                                            grau
                                        }
                                    }
                                `,
                                variables: {
                                    id: item.i.toString(),
                                    input: {
                                        layout: '',
                                        vizState: this.query,
                                        name: item.value.name,
                                        type: item.value.type,
                                        grau: item.value.grau,
                                    },
                                },
                            });
                        });
                    });
                }); // end of fetch
            },

            intermedio(item) {
                let query = JSON.parse(item.value.vizState).query;

                if (_.has(query, 'timeDimensions')) {
                    this.RollupTime(item);
                } else {
                    this.Rolluplocal(item);
                }
            },

            async change($event, item) {
                let query = JSON.parse(item.value.vizState).query;
                let type = item.value.type;
                this.beforeQuery = JSON.parse(item.value.vizState);

                if (_.has(query, 'timeDimensions')) {
                    let clicked = $event.seriesName;

                    let DataClicked = $event.name;

                    if (type != 'pie' && type != 'doughnut') {
                        this.drillDownTime(item, clicked, DataClicked);
                    }
                } else {
                    let DataClicked = $event.name;

                    this.drillDownLocal(item, DataClicked);
                }
            },

            updateLayout() {
                this.layout = this.dashboardItems.map((item) => {
                    let layout = JSON.parse(item.layout);
                    // check empty obj
                    if (Object.keys(layout).length === 0) {
                        // add new
                        layout = {
                            x: (this.dashboardItems.length * 2) % (this.colNum || 12),
                            y: this.dashboardItems.length + (this.colNum || 12), // puts it at the bottom
                            w: 6,
                            h: 30,
                            i: item.id,
                        };
                    }
                    return { ...layout, i: +item.id, value: item };
                });
            },
            // getQueryById(id) {
            //   let item = this.dashboardItems.filter((item) => +item.id === id);
            //   return JSON.parse(item[0].vizState).query;
            // },
            async deleteDashboardItem(id) {
                await this.$apollo.mutate({
                    mutation: DELETE_DASHBOARD_ITEM,
                    variables: {
                        id: id.toString(),
                    },
                });
            },
            // backDashboardItem(id) {},
            layoutUpdatedEvent(newLayout) {
                newLayout.forEach(async (l) => {
                    const item = this.dashboardItems.find((i) => {
                        return +i.id === l.i;
                    });
                    const toUpdate = JSON.stringify({
                        x: l.x,
                        y: l.y,
                        w: l.w,
                        h: l.h,
                    });
                    if (item && toUpdate !== item.layout) {
                        const newItem = { ...item };
                        const id = newItem.id;
                        delete newItem['id'];
                        delete newItem['__typename'];
                        newItem.layout = toUpdate;
                        await this.$apollo.mutate({
                            mutation: UPDATE_DASHBOARD_ITEM,
                            variables: {
                                id,
                                input: newItem,
                            },
                        });
                    }
                });
            },
        },
    };
</script>

<style>
    .height-100 {
        height: 100%;
        max-height: 100%;
        min-height: 100%;
        overflow-y: hidden;
    }
    .flex {
        display: flex;
    }
    .right {
        position: absolute;
        right: 0;
        margin-right: 5%;
        margin-top: 5%;
    }
</style>
