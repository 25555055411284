import moment from 'moment';
import _ from 'lodash';
import { computed } from 'vue';
import { colors, backgroundColor } from '../constants/colors';

export const dateToGranularity = (date, granularity) => {
    switch (granularity) {
        case 'year':
            return moment(date).format('YYYY');
        case 'quarter':
            return moment(date).format('YYYY-Q');
        case 'month':
            return moment(date).format('YYYY-MM');
        case 'week':
            return moment(date).format('YYYY-[W]WW');
        case 'day':
            return moment(date).format('YYYY-MM-DD');
        case 'hour':
            return moment(date).format('HH');
        case 'minute':
            return moment(date).format('HH:mm');
        case 'second':
            return moment(date).format('HH:mm:ss');
        default:
            return date;
    }
};

export const useCustomColors = (series) => {
    // console.log('series', series);
    const colorMap = computed(() => {
        const colorMap = [];
        series.forEach((s, i) => {
            colorMap.push({
                key: s,
                color: colors[i % colors.length],
            });
        });
        return colorMap;
    });

    const getColorByKey = (key) => {
        const color = _.find(colorMap.value, { key });
        return color ? color.color : null;
    };

    return {
        colorMap,
        getColorByKey,
    };
};

export const defaultOptions = {
    legend: {
        type: 'scroll',
    },
    grid: {
        containLabel: true,
    },

    toolbox: {
        top: '25px',
        feature: {
            saveAsImage: {
                show: true,
            },
            // magicType: { show: true, type: ['line'] },
            restore: { show: true },
            dataZoom: {
                yAxisIndex: 'none',
            },
        },
    },
    backgroundColor: backgroundColor,
};

export const defaultInitOptions = {
    renderer: 'svg',
};

export const orderTablePivot = (tablePivot, query) => {
    const dimensions = _.get(query, 'dimensions', []);
    const orders = _.get(query, 'order', []);

    // sort tablePivot by order query
    const tablePivotSorted = _.orderBy(
        tablePivot,
        orders.map((o) => {
            const dimension = dimensions.find((dimension) => dimension == o.id);
            return (row) => {
                let value = row[dimension];
                switch (dimension) {
                    case 'DimCalendar.monthOfYear':
                        value = parseInt(value);
                        break;

                    case 'DimCalendar.month':
                        value = parseInt(moment().month(value).format('M'));
                        break;
                }

                return value;
            };
        }),
        orders.map((o) => (o.desc ? 'desc' : 'asc')),
    );

    return tablePivotSorted;
};

export const sortMonths = (months) => {
    return _.orderBy(
        months,
        (month) => {
            return parseInt(moment().month(month).format('M'));
        },
        'asc',
    );
};
