export default {
    explore: 'Explorar',
    measures: {
        'FactsTableAttendance.MeanAttendanceTime': 'Tempo médio de atendimento',
        'FactsTableAttendance.MeanAttendanceTotalTime': 'Tempo médio total de atendimento',
        'FactsTableAttendance.TotalAttendance': 'Total de atendimentos',
        'FactsTableAttendance.TotalWorkers': 'Total de trabalhadores',
        'FactsTableAttendance.OccupancyRateEmployee': 'Taxa de ocupação do trabalhador',
        'FactsTableAttendance.WaitingTime': 'Tempo de espera do utilizador',
        'FactsTableAttendance.TotalDownTimeEmployee': 'Tempo parado do trabalhador',
    },
    dimensions: {
        'olap.dimensionsDimCalendar.date': 'Calendário: data completa',
        'olap.dimensionsDimCalendar.day': 'Calendário: dia',
        'olap.dimensionsDimCalendar.dayOfMonth': 'Calendário: dia do mês',
        'olap.dimensionsDimCalendar.dayOfWeek': 'Calendário: dia da semana',
        'olap.dimensionsDimCalendar.dayOfYear': 'Calendário: dia do ano',
        'olap.dimensionsDimCalendar.id': 'Calendário: id',
        'olap.dimensionsDimCalendar.month': 'Calendário: mês',
    },
};
