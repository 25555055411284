<template>
    <div class="chart-renderer" v-if="resultSet" :key="chartKey">
        <Chart v-if="showChart" :resultSet="resultSet" :type="chartType" @change="change($event)" />
        <!-- <v-chart v-if="option" class="chart" :option="option" ref="chart" @click="change($event)" /> -->
        <GoogleMaps v-if="chartType === 'map'" :resultSet="resultSet" :markers="markers"> </GoogleMaps>

        <v-row justify="center" class="ma-0">
            <v-btn v-show="!table" class="ma-2" @click="showTable()" outlined color="primary"> Values Table </v-btn>
            <v-btn v-show="table" class="ma-2" @click="BackTable()" outlined color="primary"> Back </v-btn>
            <v-btn v-show="!percentTable" class="ma-2" @click="showTablePercent()" outlined color="primary"> Percentage Table </v-btn>
            <v-btn v-show="percentTable" class="ma-2" @click="BackTablePercent()" outlined color="primary"> Back </v-btn>
        </v-row>

        <TablePercent v-if="percentTable === true" :result-set="resultSet" :chartType="chartType" :markers="markers"></TablePercent>
        <Table v-if="table === true" :result-set="resultSet" :chartType="chartType" :markers="markers"></Table>
    </div>
</template>

<script>
    import { ResultSet } from '@cubejs-client/core';

    import Table from './Table.vue';
    import TablePercent from './TablePercent.vue';
    import moment from 'moment';
    import VChart from 'vue-echarts';
    import _ from 'lodash';
    import GoogleMaps from '../KPIs/GoogleMaps.vue';
    import colors from '../../constants/colors';
    import Chart from '../../charts/Chart.vue';

    export default {
        name: 'ChartRenderer',
        props: {
            chartType: {
                type: String,
                required: true,
            },
            resultSetSerialized: {
                required: true,
                default: null,
            },
            query: {
                type: Object,
                required: false,
                default: () => ({}),
            },
        },
        data() {
            return {
                table: false,
                resultSet: this.resultSetSerialized,
                percentTable: false,
                markerss: [],
                k: 0,
                colors,
            };
        },
        components: {
            VChart,
            Table,
            TablePercent,
            GoogleMaps,
            Chart,
        },
        watch: {
            resultSetSerialized: {
                immediate: true,
                deep: true,
                handler() {
                    this.resultSet = ResultSet.deserialize(this.resultSetSerialized);
                    this.$nextTick(() => {
                        this.k++;
                    });
                },
            },
        },
        computed: {
            showChart() {
                return this.chartType !== 'map';
            },
            chartKey() {
                return this.k + '_' + this.chartType + '_' + JSON.stringify(this.query);
            },
            markers() {
                let data = this.resultSet.seriesNames().map((serie) => {
                    return this.resultSet.chartPivot().map((p) => [p.x, p[serie.key]]);
                });

                // flatten array
                data = data
                    .reduce((acc, val) => acc.concat(val), [])
                    .reduce((a, b) => {
                        a[b[0]] ? a[b[0]].push(b[1]) : (a[b[0]] = [b[1]]);
                        return a;
                    }, {});

                let i = 0;
                return Object.keys(data).map((key) => {
                    let [a, b] = key.split(',');
                    a = parseFloat(a);
                    b = parseFloat(b);

                    const lat = a > b ? a : b;
                    const lng = a > b ? b : a;
                    i++;
                    return {
                        id: i,
                        position: { lat, lng },
                        infoText: this.indicators[0].name.split('.')[1] + ': ' + data[key][0],
                        indicator: this.indicators[0].name.split('.')[1],
                        value: data[key][0],
                    };
                });
            },
            indicators() {
                this.chartKey;
                return this.resultSet.seriesNames().map((serie) => {
                    return {
                        name: serie.yValues[0],
                    };
                });
            },
        },
        methods: {
            change($event) {
                this.$emit('change', $event);
            },

            showTable() {
                this.table = true;
            },

            BackTable() {
                this.table = false;
            },
            showTablePercent() {
                this.percentTable = true;
            },

            BackTablePercent() {
                this.percentTable = false;
            },
        },
    };
</script>

<style>
    x-vue-echarts {
        min-height: 450px;
    }
    .number-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
</style>
