import _ from 'lodash';
import { dateToGranularity, defaultOptions, defaultInitOptions, useCustomColors } from './utils';
import { ResultSet } from '@cubejs-client/core';

const useRadarChart = (resultSet, _options = {}) => {
    const result = resultSet instanceof ResultSet ? resultSet : ResultSet.deserialize(resultSet);
    const granularity = _.get(result.query(), 'timeDimensions[0].granularity', null);
    const xAxis = result.chartPivot().map((p) => dateToGranularity(p.x, granularity));
    const selectedLegends = xAxis.reduce((acc, curr) => {
        acc[curr] = true;
        return acc;
    }, {});

    const maxValue = _.max(
        result.series().map((s) =>
            _.max(
                s.series
                    .filter((e) => {
                        return selectedLegends[dateToGranularity(e.x, granularity)];
                    })
                    .map((e) => {
                        return e.value;
                    }),
            ),
        ),
    );
    const customColors = useCustomColors(xAxis);

    const indicators = result.seriesNames().map((s) => {
        return {
            name: s.yValues[0],
            max: maxValue,
        };
    });
    const serie = {
        type: 'radar',
        symbolSize: 10,
        emphasis: {
            focus: 'series',
        },
        tooltip: {
            trigger: 'item',
        },
        data: xAxis.map((s) => ({
            name: s,
            value: _.flattenDeep(
                result.series().map((serie) => {
                    const series = serie.series.filter((e) => dateToGranularity(e.x, granularity) == s).map((e) => e.value);
                    return series;
                }),
            ),
            itemStyle: {
                color: customColors.getColorByKey(s),
            },
        })),
    };

    const options = {
        // tooltip: {
        //     trigger: 'item',
        // },
        radar: {
            indicator: indicators,
        },
        legend: {
            orient: 'vertical',
            left: 'left',
        },
        series: [serie],
        emphasis: {
            label: {
                show: true,
                fontSize: '20',
                fontWeight: 'bold',
            },
        },
    };

    const initOptions = {};

    return {
        result,
        customOptions: _.merge(options, defaultOptions, _options),
        initOptions: _.merge(initOptions, defaultInitOptions),
        serie,
        xAxis,
        indicators,
        granularity,
    };
};

export default useRadarChart;
