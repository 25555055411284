import Vue from 'vue';
import VueI18n from 'vue-i18n';
// import en from 'vee-validate/dist/locale/en.json';

// const validationLanguages = {
//     en,
// };

Vue.use(VueI18n);

// messages.validation = validationLanguages['en'].messages;

export const i18n = new VueI18n({
    locale: 'pt', // set locale
    fallbackLocale: 'pt',
    messages: {
        en: {},
        pt: {},
    }, // set locale messages
});
