var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"text-center background pa-0",attrs:{"fluid":""}},[_c('query-builder',{attrs:{"cubejs-api":_vm.cubejsApi,"initialVizState":_vm.vizState,"disableHeuristics":false,"css":"width: 100%"},scopedSlots:_vm._u([{key:"builder",fn:function({
                measures,
                setMeasures,
                availableMeasures,
                dimensions,
                setDimensions,
                availableDimensions,
                timeDimensions,
                setTimeDimensions,
                availableTimeDimensions,
                filters,
                setFilters,
                updateChartType,
                chartType,
            }){return [_c('v-container',{staticClass:"pa-4 pa-md-8 pt-6 background-white",attrs:{"fluid":""}},[_c('div',{staticClass:"wrap"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-autocomplete',{attrs:{"multiple":"","label":"Measures","outlined":"","hide-details":"","clearable":"","value":measures.map((i) => i.name),"items":availableMeasures,"item-text":"shortTitle","item-value":"name"},on:{"change":setMeasures}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-autocomplete',{attrs:{"multiple":"","label":"Dimensions","outlined":"","hide-details":"","clearable":"","value":dimensions.map((i) => i.name),"items":availableDimensions,"item-text":"shortTitle","item-value":"name"},on:{"change":setDimensions}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('TimeDimensionSelect',{attrs:{"availableTimeDimensions":availableTimeDimensions,"timeDimensions":timeDimensions},on:{"change":setTimeDimensions}})],1),_c('v-col',{staticStyle:{"min-width":"160px"},attrs:{"cols":"12","md":"1"}},[_c('v-autocomplete',{attrs:{"label":"Granularity","outlined":"","hide-details":"","clearable":"","item-text":"title","item-value":"name","value":timeDimensions[0] && timeDimensions[0].granularity,"items":_vm.GRANULARITIES},on:{"change":function($event){return setTimeDimensions([
                                        {
                                            dimension: timeDimensions[0]['dimension']['name'],
                                            granularity: $event,
                                            dateRange: timeDimensions[0]['dateRange'],
                                        },
                                    ])}}})],1),_c('v-col',{attrs:{"cols":"2","md":"2"}},[_c('v-autocomplete',{attrs:{"label":"Chart Type","outlined":"","hide-details":"","value":chartType,"items":_vm.chartTypes},on:{"change":updateChartType}})],1)],1),_c('FilterComponent',{attrs:{"filters":filters,"dimensions":availableDimensions.map((i) => ({ value: i.name, text: i.shortTitle })),"measures":availableMeasures.map((i) => ({ value: i.name, text: i.shortTitle })),"setFilters":setFilters}})],1)])]}},{key:"default",fn:function({ loading, resultSet, isQueryPresent, chartType, validatedQuery }){return [(!isQueryPresent)?_c('div',[_c('v-alert',{attrs:{"color":"blue","text":""}},[_vm._v("Choose one measure and one dimension to get started")])],1):_vm._e(),(resultSet && isQueryPresent)?_c('div',{staticClass:"wrap pa-4 pa-md-8"},[_c('div',{staticClass:"d-flex justify-end mb-8"},[_c('AddToDashboard',{on:{"onSave":(name) =>
                                _vm.createDashboardItem({
                                    name,
                                    query: validatedQuery,
                                    chartType,
                                    grau: _vm.grau,
                                })}})],1),(loading)?_c('div',{staticClass:"loading"},[_vm._v("Loading...")]):_vm._e(),(!loading)?_c('ChartRenderer',{attrs:{"chartType":chartType,"resultSet":resultSet}}):_vm._e()],1):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }