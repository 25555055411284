import _ from 'lodash';
import { dateToGranularity, defaultOptions, defaultInitOptions, useCustomColors } from './utils';
import { ResultSet } from '@cubejs-client/core';

const useScatterChart = (resultSet, _options = {}) => {
    const result = resultSet instanceof ResultSet ? resultSet : ResultSet.deserialize(resultSet);

    const granularity = _.get(result.query(), 'timeDimensions[0].granularity', null);
    const measures = _.get(result.query(), 'measures', []).join(' / ');
    const dimensions = _.get(result.query(), 'dimensions', []).join(' / ');
    const xAxis = result.chartPivot().map((p) => dateToGranularity(p.x, granularity));
    const customColors = useCustomColors(result.series().map((s) => s.key));

    const series = result.series().map((s) => {
        const serieName = result.seriesNames().find((e) => e.key === s.key);

        return {
            name: serieName.yValues[0],
            type: 'scatter',
            data: s.series.map((e) => ({
                value: e.value,
                itemStyle: {
                    color: customColors.getColorByKey(s.key),
                },
            })),
            symbolSize: 20,
            emphasis: {
                focus: 'series',
            },
        };
    });

    let options = {
        legend: {
            data: result.seriesNames().map((e) => ({
                name: e.yValues[0],
                itemStyle: {
                    color: customColors.getColorByKey(e.key),
                },
            })),
        },
        xAxis: {
            scale: true,
            data: xAxis,
            nameTextStyle: {
                fontSize: 15,
            },
        },
        yAxis: {
            scale: true,
        },
        tooltip: {
            trigger: 'item',
        },
        series,
    };

    const initOptions = {};

    return {
        result,
        customOptions: _.merge(options, defaultOptions, _options),
        initOptions: _.merge(initOptions, defaultInitOptions),
        series,
        xAxis,
        dimensions,
    };
};

export default useScatterChart;
