<template>
    <v-container fluid class="text-center background pa-0">
        <query-builder :cubejs-api="cubejsApi" :initialVizState="vizState" :disableHeuristics="false" css="width: 100%">
            <template
                #builder="{
                    measures,
                    setMeasures,
                    availableMeasures,
                    dimensions,
                    setDimensions,
                    availableDimensions,
                    timeDimensions,
                    setTimeDimensions,
                    availableTimeDimensions,
                    filters,
                    setFilters,
                    updateChartType,
                    chartType,
                }">
                <v-container fluid class="pa-4 pa-md-8 pt-6 background-white">
                    <div class="wrap">
                        <v-row>
                            <v-col cols="12" md="2">
                                <v-autocomplete
                                    multiple
                                    label="Measures"
                                    outlined
                                    hide-details
                                    clearable
                                    :value="measures.map((i) => i.name)"
                                    @change="setMeasures"
                                    :items="availableMeasures"
                                    item-text="shortTitle"
                                    item-value="name" />
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-autocomplete
                                    multiple
                                    label="Dimensions"
                                    outlined
                                    hide-details
                                    clearable
                                    :value="dimensions.map((i) => i.name)"
                                    @change="setDimensions"
                                    :items="availableDimensions"
                                    item-text="shortTitle"
                                    item-value="name" />
                            </v-col>
                            <v-col cols="12" md="2">
                                <TimeDimensionSelect :availableTimeDimensions="availableTimeDimensions" :timeDimensions="timeDimensions" @change="setTimeDimensions" />
                            </v-col>
                            <v-col cols="12" md="1" style="min-width: 160px">
                                <v-autocomplete
                                    label="Granularity"
                                    outlined
                                    hide-details
                                    clearable
                                    item-text="title"
                                    item-value="name"
                                    :value="timeDimensions[0] && timeDimensions[0].granularity"
                                    :items="GRANULARITIES"
                                    @change="
                                        setTimeDimensions([
                                            {
                                                dimension: timeDimensions[0]['dimension']['name'],
                                                granularity: $event,
                                                dateRange: timeDimensions[0]['dateRange'],
                                            },
                                        ])
                                    " />
                            </v-col>
                            <v-col cols="2" md="2">
                                <v-autocomplete label="Chart Type" outlined hide-details :value="chartType" @change="updateChartType" :items="chartTypes" />
                            </v-col>
                        </v-row>
                        <FilterComponent
                            :filters="filters"
                            :dimensions="availableDimensions.map((i) => ({ value: i.name, text: i.shortTitle }))"
                            :measures="availableMeasures.map((i) => ({ value: i.name, text: i.shortTitle }))"
                            :setFilters="setFilters"></FilterComponent>
                    </div>
                </v-container>
            </template>
            <template #default="{ loading, resultSet, isQueryPresent, chartType, validatedQuery }">
                <div v-if="!isQueryPresent">
                    <v-alert color="blue" text>Choose one measure and one dimension to get started</v-alert>
                </div>

                <div class="wrap pa-4 pa-md-8" v-if="resultSet && isQueryPresent">
                    <div class="d-flex justify-end mb-8">
                        <AddToDashboard
                            @onSave="
                                (name) =>
                                    createDashboardItem({
                                        name,
                                        query: validatedQuery,
                                        chartType,
                                        grau,
                                    })
                            "></AddToDashboard>
                    </div>
                    <div v-if="loading" class="loading">Loading...</div>
                    <ChartRenderer v-if="!loading" :chartType="chartType" :resultSet="resultSet"></ChartRenderer>
                </div>
            </template>
        </query-builder>
    </v-container>
</template>

<script>
    import { QueryBuilder, GRANULARITIES } from '@cubejs-client/vue';
    import TimeDimensionSelect from '../components/Explore/TimeDimensionSelect.vue';
    import ChartRenderer from '../components/Explore/ChartRenderer.vue';
    import FilterComponent from '../components/Explore/FilterComponent.vue';
    import AddToDashboard from '../components/Explore/AddToDashoboard.vue';
    import gql from 'graphql-tag';
    import getTrad from '../i18n/getTrad';

    export default {
        name: 'ExplorePage',
        mixins: [getTrad],
        props: {
            cubejsApi: {
                type: Object,
                required: true,
            },
        },

        components: {
            QueryBuilder,
            TimeDimensionSelect,
            FilterComponent,
            ChartRenderer,
            AddToDashboard,
        },

        data: () => {
            const query = {
                measures: ['FactsTableAttendance.TotalDownTimeEmployee'],
                dimensions: ['DimEmploye.code'],
                timeDimensions: [
                    {
                        dimension: 'DimCalendar.date',
                        granularity: 'year',
                    },
                ],
            };

            return {
                GRANULARITIES,
                chartTypes: ['line', 'bar', 'area', 'pie', 'doughnut', 'scatter', 'radar', 'map'],
                vizState: {
                    query,
                    chartType: 'bar',
                },
                grau: '',
            };
        },
        methods: {
            async createDashboardItem({ name, query, chartType }) {
                await this.$apollo.mutate({
                    mutation: gql`
                        mutation ($input: DashboardItemInput) {
                            createDashboardItem(input: $input) {
                                id
                                name
                            }
                        }
                    `,
                    variables: {
                        input: {
                            layout: '',
                            vizState: JSON.stringify({
                                query,
                            }),
                            name,
                            type: chartType,
                            initialVizState: JSON.stringify({
                                query,
                            }),
                            grau: '',
                        },
                    },
                });
            },
        },
    };
</script>

<style scopped>
    .background {
        background: #f3f3fb;
        min-height: 125vh;
    }

    .background-white {
        background: #fff;
    }

    .border-light {
        background: #ffffff;
        border-radius: 8px;
    }
</style>
