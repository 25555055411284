<template>
    <div class="card-wrapper">
        <h2 class="card-title">
            {{ title }}
        </h2>
        <v-row>
            <v-col v-for="(item, index) in items" :key="index" cols="6">
                <span class="status">
                    {{ item[0].toUpperCase() }}
                </span>
                <span class="count">
                    {{ parseFloat(item[1].toFixed(2)) + '%' }}
                </span>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        name: 'OrderCard',
        props: {
            title: String,
            resultSet: Object,
        },
        data() {
            return {
                items: this.resultSet.rawData().map((item) => Object.values(item).map((element) => element)),
            };
        },
    };
</script>

<style scoped>
    .card-content-wrapper {
        display: flex;
        justify-content: space-between;
        gap: 2rem;
    }
    .card-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .status {
        font-weight: bold;
        font-size: 1rem;
        color: rgba(0, 0, 0, 0.922);
    }
</style>
