<template>
    <v-container>
        <v-card class="mx-auto my-12" max-width="2200">
            <v-card-actions class="justify-center">
                <query-builder :cubejs-api="cubejsApi" :query="TotalAtendQueue">
                    <template v-slot="{ loading, resultSet }">
                        <div v-if="loading" class="loading">Loading...</div>
                        <div class="div" v-if="!loading && resultSet !== undefined">
                            <OrderCard1 :resultSet="resultSet" title="Total de atendimentos por serviços" />
                        </div>
                    </template>
                </query-builder>
            </v-card-actions>
        </v-card>
        <v-card class="mx-auto my-12" max-width="2200">
            <v-card-title class="text-h5 justify-center font-weight-bold"> Total de trabalhadores por ano</v-card-title>
            <query-builder :cubejs-api="cubejsApi" :query="TotalWorkersYear">
                <template v-slot="{ loading, resultSet }">
                    <div v-if="loading" class="loading">Loading...</div>
                    <div class="div" v-if="!loading && resultSet !== undefined">
                        <!-- <ChartRenderer v-if="!loading" chartType="pie" :resultSet="resultSet"></ChartRenderer> -->
                        <PieChart v-if="!loading" :key="'piechart-' + windowWidth" :resultSet="resultSet" />
                    </div>
                </template>
            </query-builder>
        </v-card>
        <v-card class="mx-auto my-12" max-width="2200">
            <v-card-title class="text-h5 justify-center font-weight-bold"> Tempo de espera diário do cliente na farmácia Demo </v-card-title>
            <query-builder :cubejs-api="cubejsApi" :query="WaitingTimeYearly">
                <template v-slot="{ loading, resultSet }">
                    <div v-if="loading" class="loading">Loading...</div>
                    <div v-if="!loading && resultSet !== undefined">
                        <!-- <ChartRenderer v-if="!loading" chartType="line" :resultSet="resultSet"></ChartRenderer> -->
                        <AreaChart v-if="!loading" :key="'areachart-' + windowWidth" :resultSet="resultSet" />
                    </div>
                </template>
            </query-builder>
        </v-card>
        <v-card class="mx-auto my-12" max-width="2200">
            <v-card-title class="text-h5 justify-center font-weight-bold"> Média temporal de atendimentos por empregado mensalmente em 2022 </v-card-title>
            <query-builder :cubejs-api="cubejsApi" :query="MeanAttendanceTime">
                <template v-slot="{ loading, resultSet }">
                    <div v-if="loading" class="loading">Loading...</div>
                    <div v-if="!loading && resultSet !== undefined">
                        <BarChart2 v-if="!loading" :key="'barchart-' + windowWidth" :resultSet="resultSet" />
                    </div>
                </template>
            </query-builder>
        </v-card>
        <v-card class="mx-auto my-12" max-width="2200">
            <v-card-actions class="justify-center">
                <query-builder :cubejs-api="cubejsApi" :query="PercentLotEmployee">
                    <template v-slot="{ loading, resultSet }">
                        <div v-if="loading" class="loading">Loading...</div>
                        <div class="div" v-if="!loading && resultSet !== undefined">
                            <OrderCard :resultSet="resultSet" title="Taxa de ocupação anual dos empregados em 2022" />
                        </div>
                    </template>
                </query-builder>
            </v-card-actions>
        </v-card>
        <v-card class="mx-auto my-12" max-width="2200">
            <v-card-title class="text-h5 justify-center font-weight-bold"> Total de atendimentos por empregado em 2022 </v-card-title>
            <query-builder :cubejs-api="cubejsApi" :query="TotalAtend">
                <template v-slot="{ loading, resultSet }">
                    <div v-if="loading" class="loading">Loading...</div>
                    <div v-if="!loading && resultSet !== undefined">
                        <BarChart3 v-if="!loading" :key="'barchart-' + windowWidth" :resultSet="resultSet" />
                    </div>
                </template>
            </query-builder>
        </v-card>
        <v-card class="mx-auto my-12" max-width="2200">
            <v-card-title class="text-h5 justify-center font-weight-bold"> Total de atendimentos por dia da semana e hora em 2021 </v-card-title>
            <query-builder :cubejs-api="cubejsApi" :query="TAtend2022">
                <template v-slot="{ loading, resultSet }">
                    <div v-if="loading" class="loading">Loading...</div>
                    <div v-if="!loading && resultSet !== undefined">
                        <LineChart2 v-if="!loading" :key="'linechart-' + windowWidth" :resultSet="resultSet" />
                    </div>
                </template>
            </query-builder>
        </v-card>
        <v-card class="mx-auto my-12" max-width="2200">
            <v-card-title class="text-h5 justify-center font-weight-bold"> Total de bilhetes por dia e hora </v-card-title>
            <query-builder :cubejs-api="cubejsApi" :query="TicketsHoraDia">
                <template v-slot="{ loading, resultSet }">
                    <div v-if="loading" class="loading">Loading...</div>
                    <div v-if="!loading && resultSet !== undefined">
                        <heatmap v-if="!loading" :resultSet="resultSet" />
                    </div>
                </template>
            </query-builder>
        </v-card>
    </v-container>
</template>

<script>
    // import cubejs from '@cubejs-client/core';
    import { QueryBuilder } from '@cubejs-client/vue';
    import Card from '../components/KPIs/card.vue';
    import ChartRenderer from '../components/Explore/ChartRenderer.vue';
    import OrderCard from '../components/KPIs/OrderCard';
    import OrderCard1 from '../components/KPIs/OrderCard1.vue';
    import BarChart from '../components/KPIs/BarChart.vue';
    import BarChart2 from '../components/KPIs/BarChart2.vue';
    import BarChart3 from '../components/KPIs/BarChart3.vue';
    import PieChart from '../components/KPIs/PieChart.vue';
    import LineChart2 from '../components/KPIs/LineChart2.vue';
    import GoogleMaps from '../components/KPIs/GoogleMaps.vue';
    import AreaChart from '../components/KPIs/AreaChart.vue';
    import heatmap from '../components/KPIs/heatmap.vue';

    export default {
        name: 'KPIs',
        props: {
            cubejsApi: {
                type: Object,
                required: true,
            },
        },
        components: {
            QueryBuilder,
            ChartRenderer,
            Card,
            OrderCard,
            BarChart,
            BarChart2,
            BarChart3,
            PieChart,
            LineChart2,
            GoogleMaps,
            AreaChart,
            OrderCard1,
            heatmap,
        },
        data() {
            return {
                windowWidth: 0,
                TotalAtendQueue: {
                    measures: ['FactsTableAttendance.TotalAttendance'],
                    dimensions: ['DimQueue.queueName'],
                },
                TotalWorkersYear: {
                    measures: ['FactsTableAttendance.TotalWorkers'],
                    dimensions: ['DimCalendar.year'],
                },
                MeanAttendanceTime: {
                    measures: ['FactsTableAttendance.MeanAttendanceTime'],
                    dimensions: ['DimEmploye.code'],
                    timeDimensions: [
                        {
                            dimension: 'DimCalendar.date',
                            granularity: 'month',
                        },
                    ],
                    filters: [
                        {
                            member: 'DimCalendar.year',
                            operator: 'equals',
                            values: ['2022'],
                        },
                    ],
                },
                WaitingTimeYearly: {
                    measures: ['FactsTableAttendance.WaitingTime'],
                    dimensions: ['DimLocal.name'],
                    timeDimensions: [
                        {
                            dimension: 'DimCalendar.date',
                            granularity: 'day',
                        },
                    ],
                    filters: [
                        {
                            member: 'DimCalendar.year',
                            operator: 'equals',
                            values: ['2021'],
                        },
                    ],
                },
                PercentLotEmployee: {
                    measures: ['FactsTableAttendance.OccupancyRateEmployee'],
                    dimensions: ['DimEmploye.code'],
                    filters: [
                        {
                            member: 'DimCalendar.year',
                            operator: 'equals',
                            values: ['2022'],
                        },
                    ],
                },
                TotalAtend: {
                    measures: ['FactsTableAttendance.TotalAttendance'],
                    dimensions: ['DimEmploye.code'],
                    filters: [
                        {
                            member: 'DimCalendar.year',
                            operator: 'equals',
                            values: ['2022'],
                        },
                    ],
                    timeDimensions: [
                        {
                            dimension: 'DimCalendar.date',
                            granularity: 'year',
                        },
                    ],
                },
                TAtend2022: {
                    measures: ['FactsTableAttendance.TotalAttendance'],
                    dimensions: ['DimCalendar.day'],
                    timeDimensions: [
                        {
                            dimension: 'DimTime.fulltime',
                            granularity: 'hour',
                        },
                    ],
                    filters: [
                        {
                            member: 'DimCalendar.year',
                            operator: 'equals',
                            values: ['2021'],
                        },
                    ],
                },
                TicketsHoraDia: {
                    measures: ['FactsTableAttendance.TotalTickets'],
                    dimensions: ['DimCalendar.day'],
                    timeDimensions: [
                        {
                            dimension: 'DimTime.fulltime',
                            granularity: 'hour',
                        },
                    ],
                },
            };
        },
        mounted() {
            this.windowWidth = window.innerWidth;
            window.addEventListener('resize', () => {
                this.windowWidth = window.innerWidth;
            });
        },
    };
</script>

<style>
    .background {
        background: #f3f3fb;
    }
    .dashboard {
        display: flex;
        justify-content: space-evenly;
        gap: 1rem;
        flex-wrap: wrap;
        align-items: left;
    }
    .cards-container {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        gap: 1rem;
        flex-wrap: wrap;
        align-items: center;
    }
    /* .card-wrapper {
        display: flex;
        margin-bottom: 40px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: fit-content;
        min-width: 250px;
        min-height: 100px;
        padding: 2rem;
        border-radius: 10px;
        background-color: #fafafa;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    } */
    /* .card-title {
        font-size: 1.5rem;
        font-weight: bold;
        margin: 0;
    } */
    .card-content {
        font-size: 2rem;
        /* font-weight: bold; */
        color: #5393ca;
        padding-top: 6px;
    }
</style>
