<template>
    <v-chart autoresize :init-options="initOptions" :option="customOptions" @click="change" />
</template>

<script>
    import { ResultSet } from '@cubejs-client/core';
    import VChart from 'vue-echarts';
    import useBarChart from './bar';
    import useLineChart from './line';
    import useAreaChart from './area';
    import usePieChart from './pie';
    import useDoughnutChart from './doughnut';
    import useScatterChart from './scatter';
    import useRadarChart from './radar';
    import useHeatmapChart from './heatmap';

    export default {
        name: 'Chart',
        components: {
            VChart,
        },
        props: {
            type: {
                type: String,
                required: true,
            },
            resultSet: {
                type: ResultSet,
                required: true,
                default: () => ({}),
            },
            options: {
                type: Object,
                default: () => ({}),
            },
        },
        methods: {
            change(event) {
                this.$emit('change', event);
            },
        },
        setup(props) {
            let useChart = null;
            switch (props.type) {
                case 'bar':
                    useChart = useBarChart;
                    break;
                case 'line':
                    useChart = useLineChart;
                    break;
                case 'area':
                    useChart = useAreaChart;
                    break;
                case 'pie':
                    useChart = usePieChart;
                    break;
                case 'doughnut':
                    useChart = useDoughnutChart;
                    break;
                case 'scatter':
                    useChart = useScatterChart;
                    break;
                case 'radar':
                    useChart = useRadarChart;
                    break;

                case 'heatmap':
                    useChart = useHeatmapChart;
                    break;
                default:
                    break;
            }
            const { initOptions, customOptions } = useChart(props.resultSet, props.options);

            return {
                initOptions,
                customOptions,
            };
        },
    };
</script>
