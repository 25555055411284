<template>
    <div class="charts-wrapper">
        <v-chart class="chart" :option="option" />
    </div>
</template>

<script>
    import { use } from 'echarts/core';
    import { CanvasRenderer } from 'echarts/renderers';
    import { PieChart } from 'echarts/charts';
    // import moment from 'moment';
    import { GridComponent, TooltipComponent, LegendComponent, TitleComponent } from 'echarts/components';
    import VChart from 'vue-echarts';
    // import VChart, { THEME_KEY } from 'vue-echarts';
    import { ref } from 'vue';
    use([CanvasRenderer, PieChart, GridComponent, TitleComponent, TooltipComponent, LegendComponent]);
    export default {
        name: 'PieChart',
        components: {
            VChart,
        },
        props: {
            title: String,
            resultSet: Object,
        },
        // provide: {
        //     [THEME_KEY]: 'light',
        // },
        setup(props) {
            let XYGroup = props.resultSet.series()[0].series.map((item) => [item.x, item.value]);

            let xAxis = XYGroup.map((s) => {
                return s[0];
            });

            let yAxis = XYGroup.map((s1) => {
                return { value: s1[1], name: s1[0] };
            });

            const option = ref({
                tooltip: {
                    trigger: 'item',
                    formatter: '{b} : {c}',
                },
                legend: {
                    orient: 'horizontal',

                    data: xAxis,
                },
                toolbox: {
                    feature: {
                        saveAsImage: {},
                    },
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: '20',
                        fontWeight: 'bold',
                    },
                },
                series: [
                    {
                        type: 'pie',
                        radius: '50%',
                        roseType: 'area',
                        itemStyle: {
                            borderRadius: 8,
                        },
                        data: yAxis,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)',
                            },
                        },
                        label: {
                            show: true,
                            position: 'inner',
                            formatter: function (params) {
                                return params.value + '\n';
                            },
                        },
                    },
                ],
            });

            return { option };
        },
    };
</script>
<style scoped>
    .chart {
        height: 400px;
    }
</style>
