import { SVGRenderer, CanvasRenderer } from 'echarts/renderers';
import { LegendComponent, GridComponent, TooltipComponent, DataZoomComponent, ToolboxComponent, MarkLineComponent, TitleComponent, VisualMapComponent } from 'echarts/components';
import { BarChart, LineChart, PieChart, ScatterChart, RadarChart, HeatmapChart } from 'echarts/charts';
import { use } from 'echarts/core';

use([
    SVGRenderer,
    CanvasRenderer,
    LegendComponent,
    GridComponent,
    TooltipComponent,
    DataZoomComponent,
    ToolboxComponent,
    BarChart,
    LineChart,
    PieChart,
    ScatterChart,
    RadarChart,
    MarkLineComponent,
    TitleComponent,
    HeatmapChart,
    VisualMapComponent,
]);
