import cubejs from '@cubejs-client/core';
import CubeConfig from '../constants/cube';

export const useCube = (project) => {
    const cubejsApi = cubejs('', {
        apiUrl: CubeConfig[project].CUBE_API_URL,
    });

    return cubejsApi;
};

export default useCube;
