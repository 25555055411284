import Vue from 'vue';
import App from './App.vue';
import { i18n } from './plugins/i18n';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import router from './router';

const { injections } = require('./plugins/modules').default;

loadFonts();

new Vue({
    vuetify,
    ...injections,
    router,
    i18n,
    render: (h) => h(App),
}).$mount('#app');
