import { render, staticRenderFns } from "./BarChart3.vue?vue&type=template&id=416189ae&scoped=true&"
import script from "./BarChart3.vue?vue&type=script&lang=js&"
export * from "./BarChart3.vue?vue&type=script&lang=js&"
import style0 from "./BarChart3.vue?vue&type=style&index=0&id=416189ae&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "416189ae",
  null
  
)

export default component.exports