<template>
    <v-data-table v-if="resultSet" :headers="headers" :items="items" class="elevation-1">
        <template v-slot:item.colours="{ item }">
            <v-chip :color="item.colours"> </v-chip>
        </template>
        <template slot="body.append">
            <tr class="black--text">
                <th class="title">Total</th>
                <th v-for="i in intermHeaders.length" :key="i" class="title">{{ sumField(i) }}</th>
            </tr>
        </template>
    </v-data-table>
</template>

<script>
    import { ResultSet } from '@cubejs-client/core';
    import _ from 'lodash';

    export default {
        name: 'Table',
        props: {
            resultSet: {
                type: ResultSet,
                required: true,
            },
            uniqX: {
                type: Array,
                required: true,
            },
            series: {
                type: Array,
                required: true,
            },
            indicator: {
                type: Array,
                required: true,
            },
            chartType: {
                type: String,
                required: true,
            },
            markerss: {
                type: Array,
                required: true,
            },
        },
        mounted() {},
        data() {
            return {
                intermHeaders: [],
                colors: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
            };
        },
        computed: {
            headers() {
                let x = [];
                if (this.chartType == 'radar') {
                    let zz = this.series;
                    let indicator = this.indicator;

                    for (let u = 0; indicator.length > u; u++) {
                        x.push(indicator[u].name);
                    }

                    x.unshift('c');
                    x.push('colours');

                    return x.map((key) => {
                        return {
                            text: key,
                            align: 'left',
                            sortable: true,
                            value: key,
                        };
                    });
                } else if (this.chartType == 'map') {
                    let headers = this.markerss.map((key) => {
                        return {
                            text: parseFloat(key.position.lat) + ', ' + parseFloat(key.position.lng),
                            align: 'left',
                            sortable: true,
                            value: '_' + key.id,
                        };
                    });
                    headers.unshift({
                        text: 'c',
                        align: 'left',
                        sortable: true,
                        value: 'c',
                    });
                    return headers;
                } else if (this.chartType == 'pie' || this.chartType == 'doughnut') {
                    let xx = [];
                    let un = this.uniqX;
                    console.log(un);

                    for (let u = 0; un.length > u; u++) {
                        xx.push(un[u]);
                    }
                    xx.unshift('c');
                    xx.push('colours');
                    return xx.map((key) => {
                        return {
                            text: key,
                            align: 'start',
                            sortable: true,
                            value: key,
                        };
                    });
                } else {
                    //todos exepto radar, mapa, pie and doughnut
                    let xx = Object.assign([], this.uniqX);
                    xx.unshift('c');
                    xx.push('colours');
                    return xx.map((key) => {
                        return {
                            text: key,
                            align: 'start',
                            sortable: true,
                            value: key,
                        };
                    });
                }
            },
            items() {
                // total headers
                let allHeaders = this.headers;
                let interm = this.intermHeaders;

                let ser = this.series;
                let array = [];
                let arrayData = [];
                let xx = this.headers;

                let x = [];

                let zz = this.series;

                for (let u = 0; zz.length > u; u++) {
                    x.push(zz[u].data[0].name);
                }
                x.unshift('c');

                let i = 0;
                if (this.chartType == 'radar') {
                    for (let p = 1; p < allHeaders.length - 1; p++) {
                        interm.push(allHeaders[p]);
                    }
                    let indicator = this.indicator;

                    let months = [];
                    for (let t in zz) {
                        for (let l in zz[t].data) {
                            let obj = {};
                            _.set(obj, 'c', zz[t].data[l].name);
                            _.set(obj, 'colours', this.colors[t]);
                            for (let k in zz[t].data[l].value) {
                                obj[indicator[k].name] = zz[t].data[l].value[k];
                                //_.set(obj, _.get(indicator, `${k}.name`), zz[t].data[l].value[k]);
                            }
                            months.push(Object.assign({}, obj));
                        }
                    }
                    Object.assign(array, months);
                } else if (this.chartType == 'map') {
                    for (var p = 0; p < allHeaders.length - 1; p++) {
                        interm.push(allHeaders[p]);
                    }
                    let obj = {};

                    for (let marker of this.markerss) {
                        _.set(obj, '_' + marker.id, parseFloat(((marker.value / marker.value) * 100).toFixed(2)));
                        _.set(obj, 'c', marker.indicator);
                    }

                    return [obj];
                } else if (this.chartType == 'pie' || this.chartType == 'doughnut') {
                    for (let p = 1; p < allHeaders.length - 1; p++) {
                        interm.push(allHeaders[p]);
                    }

                    let months = [];
                    let series = zz[0].data;
                    let sum = 0;

                    series.forEach((s) => {
                        sum += s.value;
                    });
                    console.log(sum);

                    for (let t in series) {
                        let obj = {};
                        _.set(obj, 'c', series[t].name);
                        _.set(obj, 'colours', this.colors[t]);
                        let k = 1;
                        obj[xx[k].text] = parseFloat(((series[t].value / sum) * 100).toFixed(2));
                        months.push(Object.assign({}, obj));
                    }

                    Object.assign(array, months);
                } else {
                    // // todos excepto radar, map, pie and doughnut
                    for (let p = 1; p < allHeaders.length - 1; p++) {
                        interm.push(allHeaders[p]);
                    }

                    ser.forEach((s) => {
                        array.push({ [xx[i].text]: s.name });
                    });
                    i++;

                    let data = [];
                    zz.forEach((s) => {
                        data.push(s.data);
                    });
                    console.log(data);

                    const sums = data[0].map((x, idx) => data.reduce((sum, curr) => sum + curr[idx], 0));

                    ser.forEach((s) => {
                        let l = 1;
                        let k = 0;
                        let obj = {};

                        for (l; xx.length > l; l++) {
                            obj[xx[l].text] = parseFloat(((s.data[k] / sums[k]) * 100).toFixed(2));

                            k++;
                        }

                        arrayData.push(obj);

                        let i = 0;
                        arrayData.forEach((s) => {
                            s.colours = this.colors[i];
                            i++;
                        });
                    });
                }
                for (let j = 0; array.length > j; j++) {
                    Object.assign(array[j], arrayData[j]);
                }
                return array;
            },
        },
        methods: {
            sumField(i) {
                let total = 0;

                let key = '';
                key = this.headers[i].value;

                const sum = this.items.reduce((accumulator, currentValue) => {
                    return (total += +currentValue[key]);
                }, 0);

                return parseFloat(sum.toFixed(0));
            },
        },
    };
</script>
