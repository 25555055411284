<template>
    <!-- <v-data-table  v-if="resultSet" :headers="headers" :items="items" :items-per-page="50" :hide-default-footer="true" class="elevation-1"></v-data-table> -->
    <v-data-table v-if="resultSet" :headers="headers" :items="itemsPercentage" class="elevation-1">
        <template v-slot:item.color="{ item }">
            <v-chip :color="item.color"> </v-chip>
        </template>
        <template slot="body.append">
            <tr class="black--text">
                <th class="title">Total</th>
                <th v-for="i in intermHeaders.length" :key="i" class="title">{{ sumField(i) }}</th>
            </tr>
        </template>
    </v-data-table>
</template>

<script>
    import { ResultSet } from '@cubejs-client/core';
    import _ from 'lodash';
    import colors from '../../constants/colors';
    import useBarChart from '../../charts/bar';
    import useLineChart from '../../charts/line';
    import useAreaChart from '../../charts/area';
    import usePieChart from '../../charts/pie';
    import useDoughnutChart from '../../charts/doughnut';
    import useScatterChart from '../../charts/scatter';
    import useRadarChart from '../../charts/radar';

    export default {
        name: 'Table',
        props: {
            resultSet: {
                type: ResultSet,
                required: true,
            },
            chartType: {
                type: String,
                required: true,
            },
            markers: {
                type: Array,
                required: true,
            },
        },
        mounted() {},
        data() {
            return {
                colors: colors,
            };
        },
        computed: {
            intermHeaders() {
                // return headers without the first column and the last column
                return this.headers.slice(1, this.headers.length - 1);
            },
            headers() {
                if (this.chartType == 'radar') {
                    const { granularity, indicators } = useRadarChart(this.resultSet);
                    const headers = [granularity];
                    for (let u = 0; indicators.length > u; u++) {
                        headers.push(indicators[u].name);
                    }
                    headers.push('color');

                    return headers.map((key) => {
                        return {
                            text: key,
                            align: 'start',
                            sortable: true,
                            value: key,
                        };
                    });
                } else if (this.chartType == 'pie' || this.chartType == 'doughnut') {
                    const { dimensions, measures } = usePieChart(this.resultSet) || useDoughnutChart(this.resultSet);
                    const headers = [dimensions, measures, 'color'];
                    return headers.map((key) => {
                        return {
                            text: key,
                            align: 'start',
                            sortable: true,
                            value: key,
                        };
                    });
                } else if (this.chartType == 'bar' || this.chartType == 'line' || this.chartType == 'area' || this.chartType == 'scatter') {
                    const { xAxis, dimensions } = useBarChart(this.resultSet) || useLineChart(this.resultSet) || useAreaChart(this.resultSet) || useScatterChart(this.resultSet);
                    const headers = [dimensions, ...xAxis, 'color'];
                    return headers.map((key) => {
                        return {
                            text: key,
                            align: 'start',
                            sortable: true,
                            value: key,
                        };
                    });
                } else {
                    //mapa
                    let headers = this.markers.map((key) => {
                        return {
                            text: parseFloat(key.position.lat) + ', ' + parseFloat(key.position.lng),
                            align: 'left',
                            sortable: true,
                            value: '_' + key.id,
                        };
                    });
                    headers.unshift({
                        text: 'c',
                        align: 'left',
                        sortable: true,
                        value: 'c',
                    });
                    return headers;
                }
            },

            items() {
                if (this.chartType == 'bar' || this.chartType == 'line' || this.chartType == 'area' || this.chartType == 'scatter') {
                    const { xAxis, dimensions, series } = useBarChart(this.resultSet) || useLineChart(this.resultSet) || useAreaChart(this.resultSet) || useScatterChart(this.resultSet);
                    let array = [];
                    for (let serie of series) {
                        let obj = {};
                        obj[dimensions] = serie.name;
                        obj.color = _.get(serie, 'data[0].itemStyle.color', 'pink');
                        let k = 0;
                        for (let d of serie.data) {
                            obj[xAxis[k]] = d.value;
                            k++;
                        }
                        array.push(obj);
                    }
                    return array;
                } else if (this.chartType == 'pie' || this.chartType == 'doughnut') {
                    const { measures, series, dimensions } = usePieChart(this.resultSet) || useDoughnutChart(this.resultSet);
                    const array = [];

                    for (let serie of series) {
                        for (let d of serie.data) {
                            let obj = {};
                            obj.color = _.get(d, 'itemStyle.color', 'pink');
                            obj[dimensions] = d.name;
                            obj[measures] = d.value;
                            array.push(obj);
                        }
                    }
                    return array;
                } else if (this.chartType == 'map') {
                    // for (var p = 0; p < allHeaders.length - 1; p++) {
                    //     interm.push(allHeaders[p]);
                    // }
                    let obj = {};
                    for (let marker of this.markers) {
                        _.set(obj, '_' + marker.id, marker.value);
                        _.set(obj, 'c', marker.indicator);
                    }

                    return [obj];
                } else {
                    //this.chartType == 'radar'
                    const { serie, indicators, granularity } = useRadarChart(this.resultSet);
                    const array = [];
                    for (let t in serie.data) {
                        let obj = {};
                        _.set(obj, granularity, serie.data[t].name);
                        for (let k in serie.data[t].value) {
                            obj.color = _.get(serie.data[t], 'itemStyle.color', 'pink');
                            obj[indicators[k].name] = serie.data[t].value[k];
                        }
                        array.push(obj);
                    }
                    return array;
                }
            },

            rowTotal() {
                // create an object with the sum of each column
                let rowTotal = {};
                for (let i = 0; this.items.length > i; i++) {
                    for (let j = 0; this.intermHeaders.length > j; j++) {
                        if (rowTotal[this.intermHeaders[j].value] == undefined) {
                            rowTotal[this.intermHeaders[j].value] = 0;
                        }
                        rowTotal[this.intermHeaders[j].value] += this.items[i][this.intermHeaders[j].value];
                    }
                }
                return rowTotal;
            },
            itemsPercentage() {
                // create an object with the percentage of each column, using the rowTotal and items
                let itemsPercentage = [];
                for (let i = 0; this.items.length > i; i++) {
                    let obj = { ...this.items[i] };
                    for (let j = 0; this.intermHeaders.length > j; j++) {
                        obj[this.intermHeaders[j].value] = parseFloat((this.items[i][this.intermHeaders[j].value] / this.rowTotal[this.intermHeaders[j].value]) * 100).toFixed(0) + '%';
                    }
                    itemsPercentage.push(obj);
                }
                return itemsPercentage;
            },
        },
        methods: {
            sumField(i) {
                // sum data in given key (property)
                let total = 0;

                let key = '';
                key = this.headers[i].value;

                const sum = this.itemsPercentage.reduce((accumulator, currentValue) => {
                    return (total += +currentValue[key].split('%')[0]);
                }, 0);
                return sum + '%';
            },
        },
    };
</script>
