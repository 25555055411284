<template>
    <v-container fluid class="pa-0 text-center">
        <query-builder @queryStatus="onQueryChange" :cubejs-api="useCube" :initialVizState="vizState" :query="query" :disableHeuristics="false">
            <template
                #builder="{
                    measures,
                    setMeasures,
                    availableMeasures,
                    dimensions,
                    setDimensions,
                    availableDimensions,
                    timeDimensions,
                    setTimeDimensions,
                    availableTimeDimensions,
                    resultSet,
                    filters,
                    setFilters,
                    updateChartType,
                    chartType,
                }">
                <v-container fluid class="pa-4 pa-md-8 pt-6 white">
                    <v-row>
                        <v-col cols="12" md="6" lg="4">
                            <v-autocomplete
                                multiple
                                :label="getTrad('measures')"
                                outlined
                                hide-details
                                clearable
                                :value="measures.map((i) => i.name)"
                                @change="setMeasures"
                                :items="availableMeasures"
                                item-text="shortTitle"
                                item-value="name" />
                        </v-col>
                        <v-col cols="12" sm="6" md="6" lg="3" xl="2">
                            <v-autocomplete
                                multiple
                                :label="getTrad('dimensions')"
                                outlined
                                hide-details
                                clearable
                                :value="dimensions.map((i) => i.name)"
                                @change="setDimensions"
                                :items="availableDimensions"
                                item-text="shortTitle"
                                item-value="name" />
                        </v-col>
                        <v-col cols="12" sm="6" md="6" lg="3" xl="2">
                            <TimeDimensionSelect :availableTimeDimensions="availableTimeDimensions" :timeDimensions="timeDimensions" @change="setTimeDimensions" />
                        </v-col>
                        <v-col cols="6" sm="6" md="3" lg="2" xl="1">
                            <v-autocomplete
                                :label="getTrad('granularity')"
                                outlined
                                hide-details
                                clearable
                                item-text="title"
                                item-value="name"
                                :value="timeDimensions[0] && timeDimensions[0].granularity"
                                :items="GRANULARITIES"
                                @change="
                                    setTimeDimensions([
                                        {
                                            dimension: timeDimensions[0]['dimension']['name'],
                                            granularity: $event,
                                            dateRange: timeDimensions[0]['dateRange'],
                                        },
                                    ])
                                " />
                        </v-col>
                        <v-col cols="6" sm="6" md="2" lg="2" xl="1">
                            <v-autocomplete label="Chart Type" outlined hide-details :value="chartType" @change="updateChartType" :items="chartTypes" />
                        </v-col>
                    </v-row>
                    <FilterComponent
                        :filters="filters"
                        :dimensions="availableDimensions.map((i) => ({ value: i.name, text: i.shortTitle }))"
                        :measures="availableMeasures.map((i) => ({ value: i.name, text: i.shortTitle }))"
                        :setFilters="setFilters"></FilterComponent>
                </v-container>
            </template>
            <template #default="{ loading, resultSet, isQueryPresent, chartType, validatedQuery }">
                <div v-if="!isQueryPresent">
                    <v-alert color="blue" text>Choose one measure and one dimension to get started</v-alert>
                </div>
                <div class="pa-4 pa-md-8 chart-renderer" v-if="resultSet && isQueryPresent">
                    <div class="d-flex justify-end mb-8">
                        <AddToDashboard
                            @onSave="
                                (name) =>
                                    createDashboardItem({
                                        name,
                                        query: validatedQuery,
                                        chartType,
                                        grau,
                                    })
                            "></AddToDashboard>
                    </div>
                    <div v-if="loading">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </div>
                    <ChartRenderer v-if="!loading" :chartType="chartType" :resultSetSerialized="resultSet" :query="validatedQuery"></ChartRenderer>
                </div>
            </template>
        </query-builder>
    </v-container>
</template>

<script>
    import { QueryBuilder, GRANULARITIES } from '@cubejs-client/vue';
    import TimeDimensionSelect from '../components/Explore/TimeDimensionSelect.vue';
    import ChartRenderer from '../components/Explore/ChartRenderer.vue';
    import FilterComponent from '../components/Explore/FilterComponent.vue';
    import AddToDashboard from '../components/Explore/AddToDashoboard.vue';
    import gql from 'graphql-tag';
    import getTrad from '../i18n/getTrad';
    import useCube from '../utils/useCube';
    import colors from '../constants/colors';

    export default {
        name: 'ExplorePage',
        mixins: [getTrad],
        components: {
            QueryBuilder,
            TimeDimensionSelect,
            FilterComponent,
            ChartRenderer,
            AddToDashboard,
        },
        data: (vm) => {
            const query = {
                measures: [],
                timeDimensions: [],
                dimensions: [],
                order: [],
            };

            return {
                chartTypes: ['line', 'bar', 'area', 'pie', 'doughnut', 'scatter', 'radar', 'map', 'heatmap'],
                vizState: {
                    chartType: 'line',
                },
                useCube: useCube(vm.$route.params.project),
                query,
                grau: '',
                colors,
            };
        },
        computed: {
            GRANULARITIES() {
                return GRANULARITIES.map((granularity) => ({
                    name: granularity.name,
                    title: this.getTrad(`granularities.${granularity.name}`),
                }));
            },
        },
        methods: {
            onQueryChange({ resultSet }) {
                // console.log(resultSet);
            },
            async createDashboardItem({ name, query, chartType }) {
                await this.$apollo.mutate({
                    mutation: gql`
                        mutation ($input: DashboardItemInput) {
                            createDashboardItem(input: $input) {
                                id
                                name
                            }
                        }
                    `,
                    variables: {
                        input: {
                            layout: '',
                            vizState: JSON.stringify({
                                query,
                            }),
                            name,
                            type: chartType,
                            initialVizState: JSON.stringify({
                                query,
                            }),
                            grau: '',
                        },
                    },
                });
            },
        },
    };
</script>

<style scopped>
    .background {
        background: #f3f3fb;
        min-height: 125vh;
    }

    .background-white {
        background: #fff;
    }

    .border-light {
        background: #ffffff;
        border-radius: 8px;
    }
</style>
