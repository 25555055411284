//! REGISTER ROUTES IN MAIN ROUTER
//? route names are prefixed with the plugin id to avoid collisions with other plugins and to avoid collisions with the main routes
//? considering a plugin with the id 'google-calendar'
//? e.g. Route with name "Home" becomes "GoogleCalendar-Home"
//? e.g. Route with path "/home" becomes "/google-calendar/home"

/* Components */
import Explore from '../views/ExplorePage';
import Dashboard from '../views/DashboardPage';
import KPIs from '../views/KPIs';

export default [
    {
        path: '/',
        redirect: '/qube',
    },
    {
        path: '/:project',
        name: 'Home',
        component: () => import('../views/App.vue'),
        children: [
            { path: '', alias: 'explore', name: 'Explore', component: Explore },
            { path: 'dashboard', name: 'Dashboard', component: Dashboard },
            { path: 'KPIs', name: 'KPIs', component: KPIs },
        ],
    },
];
