<template>
    <v-container fluid class="text-center background pa-0">
        <Grid v-if="dashboardItems.length" :dashboardItems="dashboardItems" />
    </v-container>
</template>

<script>
    import gql from 'graphql-tag';
    import Grid from '../components/Dashboard/GridPage.vue';
    export default {
        name: 'DashboardPage',
        props: {
            // cubejsApi: {
            //     type: Object,
            //     required: true,
            // },
        },
        apollo: {
            dashboardItems: {
                query: gql`
                    query {
                        dashboardItems {
                            id
                            name
                            layout
                            vizState
                            type
                            initialVizState
                            grau
                        }
                    }
                `,
                pollInterval: 1000,
                deep: true,
                update: (data) => {
                    return data['dashboardItems'];
                },
            },
        },
        components: {
            Grid,
        },
        data() {
            return {
                dashboardItems: [],
            };
        },
    };
</script>
