<template>
    <div class="filter-container">
        <v-row>
            <template>
                <v-col cols="2" class="mb-2" v-for="(filter, index) in filters" :key="index">
                    <div class="mx-auto filter-card">
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ filter['member']['title'] }}
                                    <v-icon @click="removeFilter(index, filter)">mdi-filter-remove-outline</v-icon>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </div>
                </v-col>
            </template>
        </v-row>
        <v-row v-if="showFilters">
            <v-col cols="12" md="2">
                <v-select label="Select Dimension or Measure" outlined hide-details v-model="select" @change="suggestion" :items="[...measures, ...dimensions]" />
            </v-col>
            <v-col cols="12" md="2" v-if="select">
                <v-select label="Select action" v-model="operator" item-text="text" item-value="value" outlined hide-details :items="actionItems" />
            </v-col>
            <v-col cols="12" md="2" v-if="operator">
                <v-text-field v-if="isfact" label="Value" outlined hide-details v-model="value"> </v-text-field>
                <v-autocomplete :loading="isLoading" multiple v-else label="Value" outlined hide-details v-model="value" :items="options"></v-autocomplete>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" class="d-flex flex-start pb-0 mt-2">
                <v-btn v-if="select" color="primary" @click="save(false)"> Save filter </v-btn>
                <v-btn v-else color="primary" outlined @click="save(true)"> Add filter </v-btn>
                <v-btn v-if="showFilters" color="primary" class="btn" outlined @click="close(true)"> Cancel </v-btn>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import useCube from '../../utils/useCube';
    import { sortMonths } from '../../charts/utils';
    export default {
        name: 'FilterComponent',
        props: ['filters', 'dimensions', 'measures', 'setFilters'],
        data: (vm) => ({
            useCube: useCube(vm.$route.params.project),
            options: [],
            dialog: false,
            isLoading: false,
            select: '',
            operator: '',
            value: '',
            actionItems: [
                {
                    text: 'equals',
                    value: 'equals',
                },
                {
                    text: 'beforeDate',
                    value: 'beforeDate',
                },
                {
                    text: 'inDateRange',
                    value: 'inDateRange',
                },
                {
                    text: 'afterDate',
                    value: 'afterDate',
                },
                {
                    text: 'does not equal',
                    value: 'notEquals',
                },
                {
                    text: '>',
                    value: 'gt',
                },
                {
                    text: '>=',
                    value: 'gte',
                },
                {
                    text: '<',
                    value: 'lt',
                },
                {
                    text: '<=',
                    value: 'lte',
                },
            ],
            showFilters: false,
        }),
        computed: {
            isfact() {
                return this.select.toLocaleLowerCase().startsWith('facts');
            },
        },
        methods: {
            close(showFilters) {
                this.showFilters = Boolean(showFilters);
                this.showFilters = false;
                this.select = '';
            },
            save(showFilters) {
                this.showFilters = Boolean(showFilters);
                if (![this.select, this.operator, this.value].every((value) => value !== '' && value != null)) {
                    return;
                }
                this.dialog = false;

                const newFilters = [
                    ...this.filters.map((filter) => ({
                        member: filter.member.name,
                        operator: filter.operator,
                        values: filter.values,
                    })),
                    {
                        member: this.select,
                        operator: this.operator,
                        values: this.value.toString().split(','),
                    },
                ];
                this.setFilters(newFilters);
                this.clearFilter();

                this.showFilters = false;
                this.$emit('save', newFilters);
            },
            removeFilter(index) {
                let filter = this.filters;
                let filterRemoved = filter.splice(index, 1);
                //filter.splice(index, 1);
                let newFilters = [];

                if (filter.length > 0) {
                    for (let i = 0; filter.length > i; i++) {
                        newFilters.push({
                            member: filter[i].member.name,
                            operator: filter[i].operator,
                            values: filter[i].values,
                        });
                    }
                } else {
                    newFilters = filter;
                }
                this.setFilters(newFilters);
                this.$emit('removeFilter', filterRemoved);
            },
            clearFilter() {
                this.select = '';
                this.operator = '';
                this.value = '';
            },
            suggestion() {
                this.options = [];
                this.value = '';
                this.isLoading = true;

                this.useCube
                    .load({
                        dimensions: [this.select],
                    })
                    .then((resultSet) => {
                        let data = resultSet.loadResponses.map((response) => response.data.map((item) => item[this.select])).flat();
                        data = [...new Set(data)];
                        if (this.select.toLowerCase() == 'dimcalendar.month') {
                            data = sortMonths(data);
                        }
                        this.options = data;
                        this.isLoading = false;
                    })
                    .catch((error) => {
                        console.log(error);
                        this.isLoading = false;
                    });
            }, //end suggestions
        }, // end methods
    };
</script>
<style scoped>
    .filter-card {
        background: #f5f5f5;
        border-radius: 3px;
    }
    .filter-container {
        margin-top: 20px;
    }
    .col-2 {
        max-width: 100% !important;
    }
    .btn {
        margin-left: 2%;
    }
</style>
