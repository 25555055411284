import _ from 'lodash';
import { ResultSet } from '@cubejs-client/core';
import { dateToGranularity, useCustomColors, defaultOptions, defaultInitOptions } from './utils';

const useDoughnutChart = (resultSet, _options = {}) => {
    const result = resultSet instanceof ResultSet ? resultSet : ResultSet.deserialize(resultSet);
    const seriesNames = result.chartPivot().map((c) => c.x);
    const dimensions = _.get(result.query(), 'dimensions', []).join(' / ');
    const measures = _.get(result.query(), 'measures', []).join(' / ');
    const customColors = useCustomColors(result.series().map((s) => s.key));

    const series = result.series().map((s) => {
        return {
            type: 'pie',
            radius: ['40%', '70%'],
            data: s.series.map((e) => ({
                name: e.x,
                value: e.value,
                itemStyle: {
                    color: customColors.getColorByKey(e.x),
                },
            })),
            itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2,
            },
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)',
                },
            },
            label: {
                show: true,
                position: 'inner',
                formatter: function (params) {
                    return params.value + '\n';
                },
            },
            labelLine: {
                show: false,
            },
        };
    });

    let options = {
        tooltip: {
            trigger: 'item',
        },
        legend: {
            orient: 'vertical',
            left: '5%',
            data: seriesNames.map((name) => ({
                name: name,
                itemStyle: {
                    color: customColors.getColorByKey(name),
                },
            })),
        },
        series,
        emphasis: {
            label: {
                show: true,
                fontSize: '20',
                fontWeight: 'bold',
            },
        },
    };

    const initOptions = {};

    return {
        result,
        customOptions: _.merge(options, defaultOptions, _options),
        initOptions: _.merge(initOptions, defaultInitOptions),
        series,
        seriesNames,
        dimensions,
        measures,
    };
};

export default useDoughnutChart;
